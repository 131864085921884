.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px 10px 5px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #3178d0;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
}

#doc_status_color option[value="Not Uploaded"] {
  background-color: #f1c1bf ; /* Background color for Option 3 */
  color:#840903; /* Text color for Option 3 */
}
#doc_status_color option[value="Awaiting Review"] {
  background-color:#98a8d4; /* Background color for Option 3 */
  color: #012587 ; /* Text color for Option 3 */
}
#doc_status_color option[value="Awaiting Reupload"] {
  background-color: #ececbd; /* Background color for Option 3 */
  color: #716802  /* Text color for Option 3 */
}
#doc_status_color option[value="Approved"] {
  background-color: #b8f1d0; /* Background color for Option 3 */
  color: #03692f; /* Text color for Option 3 */
}
#doc_status_color option[value="N/A"] {
  background-color: #ff99e6; /* Background color for Option 3 */
  color: #800060; /* Text color for Option 3 */
}
#doc_status_color option {
  font-weight: bold;
}
.notUpload{
  background-color: #f1c1bf ; /* Background color for Option 3 */
  color:#840903; /* Text color for Option 3 */
}
.awaitingReview {
  background-color:#98a8d4; /* Background color for Option 3 */
  color: #012587 ; /* Text color for Option 3 */
}
.awaitingReupload {
  background-color: #ececbd; /* Background color for Option 3 */
  color: #716802  /* Text color for Option 3 */
}
.approved {
  background-color: #b8f1d0; /* Background color for Option 3 */
  color: #03692f; /* Text color for Option 3 */
}
.NA {
  background-color: #ff99e6; /* Background color for Option 3 */
  color: #800060; /* Text color for Option 3 */
}