// load base demo styles. for demo purpose, the style will be loaded here
// for production, include the demo base style in /src/index.html file to load the css earlier
.kt-grid--animateContent {
  transform: translate3d(0, -5%, 0);
  opacity: 0;
  position: static;
  &-finished {
    transform: translate3d(0, 0%, 0);
    transition: 0.2s ease-in-out;
    opacity: 1;
  }
}
.kt-header__topbar .kt-header__topbar-wrapper {
  .notifyIcon{
    height: 20px;
    border-radius: 50%;
    width: 20px;
    text-align: center;
    top: 5px;
    color: #fff;
    position:absolute;
    right: 0px;
  }
}
.hrztl-icon .kt-header__topbar-wrapper, .hrztl-icon .kt-header__topbar-item--quick-panel {
  background-color: #f0f8ff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
  .notifyIcon{
  height: 20px;
  border-radius: 50%;
  width: 20px;
  text-align: center;
  top: -5px;
  color: #fff;
  position:absolute;
  left: 20px;
  }
}
.hrztl-icon .kt-header-menu-light .fa, .kt-header-menu-light .actionicon{
  margin-left: 8px;
  margin-top: 5px;
}
.css-15h0e5v-menu {
  max-height: none !important;
}