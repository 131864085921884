.elementor-kit-4354 {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

h1.entry-title {
    display: var(--page-title-display);
}

@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px;
    }
}

@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px;
    }
}