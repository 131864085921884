body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    width: 100%;
    font-weight: 400;
    background: rgb(255, 255, 255);
}

a {
    text-decoration: none;
    color: inherit;
}

a:not(.btn):hover {
    text-decoration: underline;
}

input,
select,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

main {
    overflow: hidden;
}

u>span {
    text-decoration: inherit;
}

ol,
ul {
    padding-left: 2.5rem;
    margin: .625rem 0;
}

p {
    word-wrap: break-word;
}

h1>span,
h2>span,
h3>span,
h4>span,
h5>span,
h6>span {
    word-wrap: break-word;
}

iframe {
    border: 0;
}

* {
    box-sizing: border-box;
}

:root.js-text-scaling {
    --mobile-font-size: 4vw;
    --default-font-size: 16px;
}

.item-absolute {
    position: absolute;
}

.item-relative {
    position: relative;
}

.item-block {
    display: block;
    height: 100%;
    width: 100%;
}

.item-cover {
    z-index: 1000030;
}

.item-breakword {
    word-wrap: break-word;
}

.item-content-box {
    box-sizing: content-box;
}

.hidden {
    display: none;
}

.clearfix {
    clear: both;
}

sup,
sub {
    margin-left: 0.1rem;
    line-height: 0;
}

img.wp-smiley,
img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@supports (-webkit-overflow-scrolling:touch) {
    @media (-webkit-min-device-pixel-ratio:2),
    (min-resolution:192dpi) {
        .image[src$=".svg"] {
            width: calc(100% + 1px);
        }
    }
}

.show-for-sr {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.headline {
    font-weight: 700;
}

.btn {
    line-height: 120%;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
    transition: border .5s;
    border: 0;
    white-space: normal;
    display: table-cell;
    vertical-align: middle;
    padding: 0;
}

.section-fit {
    max-width: 400px;
}

:root {
    --section-relative-margin: 0 auto;
}

.section-relative {
    position: relative;
    margin: 0 auto;
}

.js-text-scaling .section-relative {
    margin: var(--section-relative-margin);
}

.section-inner {
    height: 100%;
}

#page-block-anptm40e1sb {
    height: 72.375rem;
    max-width: 100%;
}

#page-block-anptm40e1sb .section-holder-border {
    border: 0;
}

#page-block-anptm40e1sb .section-block {
    background: rgb(255, 255, 255);
    height: 72.375rem;
}

#page-block-anptm40e1sb .section-holder-overlay {
    display: none;
}

#element-134 {
    top: 1.25rem;
    left: 1.25rem;
    height: 4.375rem;
    width: 8.5rem;
    z-index: 20;
}

#element-78 {
    top: 22.5rem;
    left: 0.4375rem;
    height: 47.25rem;
    width: 24rem;
    z-index: 4;
}

.circle {
    border-radius: 50%;
}

.shape {
    height: inherit;
}

.line-horizontal {
    height: .625rem;
}

.line-vertical {
    height: 100%;
    margin-right: .625rem;
}

[class*='line-'] {
    box-sizing: content-box;
}

#element-78 .shape {
    border: 0;
    border-radius: 0 0 0 0;
    background: rgb(247, 247, 247);
    box-shadow: rgba(93, 93, 93, 0.12) 0 0 10px 2px;
}

#element-73 {
    top: 24.5625rem;
    left: 0.4375rem;
    height: 1.9375rem;
    width: 24rem;
    z-index: 5;
    color: #37465A;
    font-size: 1.3622rem;
    line-height: 1.925rem;
    text-align: center;
}

#element-73 .x_a2e00130 {
    text-align: center;
    line-height: 1.9375rem;
    font-size: 1.3622rem;
}

#element-73 .x_f929cec8 {
    color: #1d1d28;
}

#element-76 {
    top: 27.375rem;
    left: 2.9375rem;
    height: 29.6875rem;
    width: 18.75rem;
    z-index: 6;
}

.btn-shadow {
    box-shadow: 0 1px 3px rgba(1, 1, 1, 0.5);
}

.lightbox {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
}

.lightbox-dim {
    background: rgba(0, 0, 0, 0.85);
    height: 100%;
    animation: fade-in .5s ease-in-out;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    padding: 30px 0;
}

.lightbox-content {
    background-color: #fefefe;
    border-radius: 3px;
    position: relative;
    margin: auto;
    animation: slide-down .5s ease-in-out;
}

.lightbox-opened {
    display: block;
}

.lightbox-close {
    width: 26px;
    right: 0;
    top: -10px;
    cursor: pointer;
}

.lightbox-close-btn {
    padding: 0;
    border: none;
    background: none;
}

.lightbox-btn-svg {
    display: block;
}

.lightbox-close-icon {
    fill: #fff;
}

.notification-text {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    width: 100%;
}

.modal-on {
    overflow: hidden;
}

.popup-link {
    cursor: pointer;
}

.form {
    font-size: 1.25rem;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
    min-width: 0;
}

.form-input {
    color: transparent;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    font-family: inherit;
    width: 100%;
    height: 3.5rem;
    margin: 0.5rem 0;
    padding: 0.5rem 0.625rem 0.5625rem;
}

.form-input::placeholder {
    opacity: 1;
    color: transparent;
}

.form-textarea {
    display: inline-block;
    vertical-align: top;
    resize: none;
}

.form-select {
    background: url("//v.fastcdn.co/a/img/builder2/select-arrow-drop-down.png") no-repeat right;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: transparent;
}

.form-label {
    display: inline-block;
    color: transparent;
}

.form-label-title {
    display: block;
    line-height: 1.1;
    width: 100%;
    padding: 0.75rem 0 0.5625rem;
    margin: 0.5rem 0 0.125rem;
}

.form-multiple-label:empty {
    display: block;
    height: 0.8rem;
    margin-top: .375rem;
}

.form-label-outside {
    margin: 0.3125rem 0 0;
}

.form-multiple-input {
    position: absolute;
    opacity: 0;
}

.form-multiple-label {
    position: relative;
    padding-top: 0.75rem;
    line-height: 1.05;
    margin-left: 1.5625rem;
}

.form-multiple-label:before {
    content: "";
    display: inline-block;
    box-sizing: inherit;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid #8195a8;
    margin-right: 0.5rem;
    vertical-align: -2px;
    position: absolute;
    left: -1.5625rem;
}

.form-checkbox-label:after {
    content: "";
    width: 0.25rem;
    height: 0.5rem;
    position: absolute;
    top: 0.8rem;
    left: -1.25rem;
    transform: rotate(45deg);
    border-right: 0.1875rem solid;
    border-bottom: 0.1875rem solid;
    color: #fff;
}

.form-radio-label:before {
    border-radius: 50%;
}

.form-multiple-input:focus+.form-multiple-label:before {
    border: 2px solid #308dfc;
}

.form-multiple-input:checked+.form-radio-label:before {
    border: 0.3125rem solid #308dfc;
}

.form-multiple-input:checked+.form-checkbox-label:before {
    background-color: #308dfc;
    border: 0;
}

.form-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    min-height: 100%;
}

.form-input-inner-shadow {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.28);
}

body#landing-page .user-invalid-label {
    color: #e85f54;
}

body#landing-page .user-invalid {
    border-color: #e85f54;
}

.form-messagebox {
    transform: translate(0.4375rem, -0.4375rem);
}

.form-messagebox:before {
    content: "";
    position: absolute;
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    transform: rotate(45deg);
    background-color: #e85f54;
    top: -0.1875rem;
    left: 25%;
}

.form-messagebox-contents {
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
    background-color: #e85f54;
    padding: 0.4375rem 0.9375rem;
    max-width: 250px;
    word-wrap: break-word;
    margin: auto;
}

.form-messagebox-top {
    transform: translate(0, -1rem);
}

.form-messagebox-top:before {
    bottom: -0.1875rem;
    top: auto;
}

#element-76 .btn.btn-effect3d:active {
    box-shadow: none;
}

#element-76 .btn:hover {
    background: #EF442F;
    color: #FFFFFF;
}

#element-76 .btn {
    background: #EF442F;
    color: #FFFFFF;
    border-color: #ff3600;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    font-size: 1.2384rem;
    font-weight: 700;
    height: 3.6875rem;
    width: 18.75rem;
    border-radius: 0;
}

#element-76 .form-label {
    color: #333333;
}

#element-76 ::placeholder {
    color: #333333;
}

#element-76 .form-input {
    color: #000000;
    background-color: #FFFFFF;
    border-color: #A3BAC6;
}

#element-76 .form-select[aria-invalid="false"] {
    color: #000000;
}

#element-76 .user-invalid {
    border-color: #E12627;
}

#element-76 input::placeholder,
#element-76 .form-label-inside {
    color: #333333;
}

#element-76 select.valid {
    color: #000000;
}

#element-76 .form-btn-geometry {
    top: 31.5625rem;
    left: 0.0625rem;
    height: 3.6875rem;
    width: 18.75rem;
    z-index: 6;
}

#element-173 {
    top: 8.0625rem;
    left: 4.4375rem;
    height: 2.75rem;
    width: 13.75rem;
    z-index: 45;
    color: #37465A;
    font-size: 0.9907rem;
    line-height: 1.4rem;
    text-align: left;
    font-weight: 400;
}

#element-173 .x_0a34168d {
    text-align: left;
    line-height: 1.375rem;
    font-size: 0.9907rem;
}

#element-173 .x_f929cec8 {
    color: #1d1d28;
}

#element-173 strong {
    font-weight: 700;
}

#element-173.headline {
    font-weight: 400;
}

#element-172 {
    top: 8.125rem;
    left: 1.25rem;
    height: 1.3125rem;
    width: 2.125rem;
    z-index: 44;
}

#element-172 .shape {
    border-bottom: 1px solid #333333;
}

#element-23 {
    top: 7.25rem;
    left: 1.25rem;
    height: 4.25rem;
    width: 19.75rem;
    z-index: 7;
    color: #37465A;
    font-size: 1.7337rem;
    line-height: 1rem !important;
    text-align: left;
}

#element-23 .x_21a56353 {
    text-align: left;
    line-height: 1rem !important;
    font-size: 1.7337rem;
}

#element-23 .x_f929cec8 {
    color: #1d1d28;
    font-weight: bold;
}

#element-24 {
    top: 12.375rem;
    left: 1.25rem;
    height: 7.25rem;
    width: 22.5rem;
    z-index: 8;
    color: #37465A;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
}

#element-24 .x_b6c3675a {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
}

#element-24 .x_93908647 {
    color: #333333;
}

#element-209 {
    top: 63.8125rem;
    left: 3.6875rem;
    height: 3.9375rem;
    width: 17.25rem;
    z-index: 49;
    color: #37465A;
    font-size: 0.805rem;
    line-height: 1.3rem;
    text-align: center;
}

#element-209 .x_70ee4658 {
    text-align: center;
    line-height: 1.3125rem;
    font-size: 0.805rem;
}

#element-209 .x_93908647 {
    color: #333333;
}

#page-block-ciascqo1yf {
    height: 58.125rem;
    max-width: 100%;
}

#page-block-ciascqo1yf .section-holder-border {
    border: 0;
}

#page-block-ciascqo1yf .section-block {
    background: rgb(29, 29, 40);
    height: 58.125rem;
}

#page-block-ciascqo1yf .section-holder-overlay {
    display: none;
}

#element-34 {
    top: 2.5rem;
    left: 1.25rem;
    height: 6.5625rem;
    width: 22.5rem;
    z-index: 9;
    color: #37465A;
    font-size: 1.548rem;
    line-height: 2.1875rem;
    text-align: center;
}

#element-34 .x_67ab24c6 {
    text-align: center;
    line-height: 2.1875rem;
    font-size: 1.548rem;
}

#element-34 .x_f2074b6c {
    color: #ffffff;
}

#element-162 {
    top: 11.375rem;
    left: 1.25rem;
    height: 1.8125rem;
    width: 1.8125rem;
    z-index: 41;
}

#element-51 {
    top: 11.375rem;
    left: 3.9375rem;
    height: 1.75rem;
    width: 16.5625rem;
    z-index: 40;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-51 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-51 .x_f2074b6c {
    color: #ffffff;
}

#element-190 {
    top: 39.25rem;
    left: 0;
    height: 15.1875rem;
    width: 24.9375rem;
    z-index: 21;
}

#element-190 .shape {
    border: 0.0625rem solid #A3BAC6;
    background: rgb(240, 243, 245);
    opacity: 0.26;
}

#element-135 {
    top: 39.9375rem;
    left: 0;
    height: 13.75rem;
    width: 24.9375rem;
    z-index: 22;
}

#element-157 {
    top: 14.875rem;
    left: 1.25rem;
    height: 1.75rem;
    width: 1.75rem;
    z-index: 33;
}

#element-58 {
    top: 14.875rem;
    left: 3.875rem;
    height: 1.75rem;
    width: 16.5625rem;
    z-index: 32;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-58 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-58 .x_f2074b6c {
    color: #ffffff;
}

#element-159 {
    top: 18.3125rem;
    left: 1.25rem;
    height: 1.75rem;
    width: 1.75rem;
    z-index: 35;
}

#element-44 {
    top: 18.3125rem;
    left: 3.9375rem;
    height: 1.75rem;
    width: 16.5625rem;
    z-index: 34;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-44 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-44 .x_f2074b6c {
    color: #ffffff;
}

#element-160 {
    top: 21.75rem;
    left: 1.25rem;
    height: 1.75rem;
    width: 1.75rem;
    z-index: 37;
}

#element-41 {
    top: 21.75rem;
    left: 3.875rem;
    height: 1.75rem;
    width: 16.5625rem;
    z-index: 36;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-41 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-41 .x_f2074b6c {
    color: #ffffff;
}

#element-161 {
    top: 25.1875rem;
    left: 1.25rem;
    height: 1.75rem;
    width: 1.75rem;
    z-index: 39;
}

#element-65 {
    top: 25.1875rem;
    left: 3.875rem;
    height: 1.75rem;
    width: 18.5rem;
    z-index: 38;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-65 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-65 .x_f2074b6c {
    color: #ffffff;
}

#element-163 {
    top: 28.8125rem;
    left: 1.25rem;
    height: 1.8125rem;
    width: 1.8125rem;
    z-index: 43;
}

#element-38 {
    top: 28.875rem;
    left: 3.9375rem;
    height: 1.75rem;
    width: 20rem;
    z-index: 42;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: left;
}

#element-38 .x_b558b569 {
    text-align: left;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-38 .x_f2074b6c {
    color: #ffffff;
}

#element-208 {
    top: 32.5rem;
    left: 1.25rem;
    height: 1.875rem;
    width: 1.875rem;
    z-index: 48;
}

#element-207 {
    top: 32.5rem;
    left: 4rem;
    height: 4rem;
    width: 14.0625rem;
    z-index: 47;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: left;
}

#element-207 .x_9811881d {
    text-align: left;
    line-height: 2rem;
    font-size: 1.2384rem;
}

#element-207 .x_f2074b6c {
    color: #ffffff;
}

#page-block-ajyyc6vf2hc {
    height: 51.8125rem;
    max-width: 100%;
}

#page-block-ajyyc6vf2hc .section-holder-border {
    border: 0;
}

#page-block-ajyyc6vf2hc .section-block {
    background: rgb(247, 247, 247);
    height: 51.8125rem;
}

#page-block-ajyyc6vf2hc .section-holder-overlay {
    display: none;
}

#element-123 {
    top: 2.5rem;
    left: 1.25rem;
    height: 6.5625rem;
    width: 22.5rem;
    z-index: 19;
    color: #37465A;
    font-size: 1.548rem;
    line-height: 2.1875rem;
    text-align: center;
}

#element-123 .x_67ab24c6 {
    text-align: center;
    line-height: 2.1875rem;
    font-size: 1.548rem;
}

#element-123 .x_f929cec8 {
    color: #1d1d28;
}

#element-151 {
    top: 11.75rem;
    left: 10.9375rem;
    height: 3.0625rem;
    width: 3.0625rem;
    z-index: 31;
}

#element-4 {
    top: 15.875rem;
    left: 4.375rem;
    height: 1.75rem;
    width: 16.1875rem;
    z-index: 29;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: center;
}

#element-4 .x_7df451b3 {
    text-align: center;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-4 .x_f929cec8 {
    color: #1d1d28;
}

#element-5 {
    top: 18.3125rem;
    left: 4.125rem;
    height: 5.0625rem;
    width: 16.625rem;
    z-index: 30;
    color: #37465A;
    font-size: 1.0526rem;
    line-height: 1.7rem;
    text-align: center;
}

#element-5 .x_7a88b5a4 {
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.0526rem;
}

#element-5 .x_93908647 {
    color: #333333;
}

#element-150 {
    top: 25.375rem;
    left: 10.875rem;
    height: 3.25rem;
    width: 3.25rem;
    z-index: 28;
}

#element-7 {
    top: 29.6875rem;
    left: 5rem;
    height: 1.75rem;
    width: 14.875rem;
    z-index: 26;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: center;
}

#element-7 .x_7df451b3 {
    text-align: center;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-7 .x_f929cec8 {
    color: #1d1d28;
}

#element-8 {
    top: 32.1875rem;
    left: 3.5625rem;
    height: 5.0625rem;
    width: 17.8125rem;
    z-index: 27;
    color: #37465A;
    font-size: 1.0526rem;
    line-height: 1.7rem;
    text-align: center;
}

#element-8 .x_7a88b5a4 {
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.0526rem;
}

#element-8 .x_93908647 {
    color: #333333;
}

#element-149 {
    top: 39.3125rem;
    left: 10.9375rem;
    height: 3.125rem;
    width: 3.125rem;
    z-index: 25;
}

#element-10 {
    top: 43.5rem;
    left: 0.875rem;
    height: 1.75rem;
    width: 23.25rem;
    z-index: 23;
    color: #37465A;
    font-size: 1.2384rem;
    line-height: 1.75rem;
    text-align: center;
}

#element-10 .x_7df451b3 {
    text-align: center;
    line-height: 1.75rem;
    font-size: 1.2384rem;
}

#element-10 .x_f929cec8 {
    color: #1d1d28;
}

#element-11 {
    top: 46rem;
    left: 4.3125rem;
    height: 3.375rem;
    width: 16.375rem;
    z-index: 24;
    color: #37465A;
    font-size: 1.0526rem;
    line-height: 1.7rem;
    text-align: center;
}

#element-11 .x_7a88b5a4 {
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.0526rem;
}

#element-11 .x_93908647 {
    color: #333333;
}

#page-block-9umqgxs20o {
    height: 27.375rem;
    max-width: 100%;
}

#page-block-9umqgxs20o .section-holder-border {
    border: 0;
}

#page-block-9umqgxs20o .section-block {
    background: repeat rgb(250, 250, 250) 50% 50% / cover;
    background-image: url(//v.fastcdn.co/u/e36f2c7e/62346883-0-vision-bg.d0fcbe3c.jpg), url(//v.fastcdn.co/t/e36f2c7e/803aaa74/1706403743-62346883-ghost-vision-bg.d0fcbe3c.jpg);
    height: 27.375rem;
}

#page-block-9umqgxs20o .section-holder-overlay {
    display: none;
}

#element-205 {
    top: 2.5rem;
    left: 5.875rem;
    height: 28.875rem;
    width: 13.25rem;
    z-index: 46;
}

#element-70 {
    top: 2.5625rem;
    left: 1.375rem;
    height: 7.3125rem;
    width: 16.9375rem;
    z-index: 13;
    color: #37465A;
    font-size: 1.7337rem;
    line-height: 2.45rem;
    text-align: left;
}

#element-70 .x_bc34b3ff {
    text-align: left;
    line-height: 2.4375rem;
    font-size: 1.7337rem;
}

#element-70 .x_f2074b6c {
    color: #ffffff;
}

#element-71 {
    top: 10.75rem;
    left: 1.375rem;
    height: 7.25rem;
    width: 19.25rem;
    z-index: 14;
    color: #37465A;
    font-size: 1.1146rem;
    line-height: 1.8rem;
    text-align: left;
}

#element-71 .x_8c071feb {
    text-align: left;
    line-height: 1.8125rem;
    font-size: 1.1146rem;
}

#element-71 .x_f2074b6c {
    color: #ffffff;
}

#element-74 {
    top: 19.9375rem;
    left: 1.375rem;
    height: 3.6875rem;
    width: 18.625rem;
    z-index: 12;
}

#element-74 .btn.btn-effect3d:active {
    box-shadow: none;
}

#element-74 .btn:hover {
    background: #6C30DD;
    color: #FFFFFF;
}

#element-74 .btn {
    background: #7B4FCD;
    color: #FFFFFF;
    font-size: 1.2384rem;
    font-weight: 700;
    font-style: normal;
    height: 3.6875rem;
    width: 18.625rem;
    border-radius: 0;
}

#page-block-3fteat1nlsk {
    height: 21.375rem;
    max-width: 100%;
}

#page-block-3fteat1nlsk .section-holder-border {
    border: 0;
}

#page-block-3fteat1nlsk .section-block {
    background: rgb(29, 29, 40);
    height: 21.375rem;
}

#page-block-3fteat1nlsk .section-holder-overlay {
    display: none;
}

#element-85 {
    top: 2.0625rem;
    left: 1.1875rem;
    height: 1.5625rem;
    width: 8.25rem;
    z-index: 10;
    color: #37465A;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: left;
}

#element-85 .x_9a9d3d17 {
    text-align: left;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
}

#element-85 .x_f2074b6c {
    color: #ffffff;
}

#element-86 {
    top: 4.375rem;
    left: 1.1875rem;
    height: 3.25rem;
    width: 14.9375rem;
    z-index: 11;
    color: #37465A;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
}

#element-86 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
}

#element-86 .x_50f304a1 {
    color: #9e9e9e;
}

#element-87 {
    top: 9.3125rem;
    left: 14.9375rem;
    height: 1.5625rem;
    width: 4.875rem;
    z-index: 15;
    color: #37465A;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: left;
}

#element-87 .x_9a9d3d17 {
    text-align: left;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
}

#element-87 .x_f2074b6c {
    color: #ffffff;
}

#element-88 {
    top: 11.625rem;
    left: 14.9375rem;
    height: 1.625rem;
    width: 9rem;
    z-index: 16;
    color: #37465A;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
}

#element-88 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
}

#element-88 .x_50f304a1 {
    color: #9e9e9e;
}

#element-89 {
    top: 9.3125rem;
    left: 1.1875rem;
    height: 1.5625rem;
    width: 3.5625rem;
    z-index: 17;
    color: #37465A;
    font-size: 1.1146rem;
    line-height: 1.575rem;
    text-align: left;
}

#element-89 .x_9a9d3d17 {
    text-align: left;
    line-height: 1.5625rem;
    font-size: 1.1146rem;
}

#element-89 .x_f2074b6c {
    color: #ffffff;
}

#element-90 {
    top: 11.625rem;
    left: 1.1875rem;
    height: 1.625rem;
    width: 7.1875rem;
    z-index: 18;
    color: #37465A;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: left;
}

#element-90 .x_fae6260b {
    text-align: left;
    line-height: 1.625rem;
    font-size: 0.9907rem;
}

#element-90 .x_50f304a1 {
    color: #9e9e9e;
}

#element-84 {
    top: 13.5625rem;
    left: 1.1875rem;
    height: 6.25rem;
    width: 20.0625rem;
    z-index: 3;
}

.full-size {
    width: 100%;
    height: 100%;
}

.html-widget__text-center {
    text-align: center;
}

@media screen and (max-width:400px) {
    :root {
        font-size: 4vw;
    }

    :root.js-text-scaling {
        font-size: var(--mobile-font-size);
    }
}

@media screen and (min-width:401px) and (max-width:767px) {
    :root {
        font-size: 16px;
    }

    :root.js-text-scaling {
        font-size: var(--default-font-size);
    }
}

@media screen and (min-width:768px) and (max-width:1200px) {
    :root {
        font-size: 1.33vw;
    }
}

@media screen and (min-width:768px) and (max-width:7680px) {
    .hidden-desktop {
        display: none;
    }
}

@media screen and (max-width:767px) {
    .hidden-mobile {
        display: none;
    }
}

@media screen and (min-width:768px) {
    .section-fit {
        max-width: 60rem;
    }

    #page-block-anptm40e1sb {
        height: 45.0625rem;
        max-width: 100%;
    }

    #page-block-anptm40e1sb .section-holder-border {
        border: 0;
    }

    #page-block-anptm40e1sb .section-block {
        background: rgb(255, 255, 255);
        height: 45.0625rem;
    }

    #page-block-anptm40e1sb .section-holder-overlay {
        display: none;
    }

    #element-134 {
        top: 1.5rem;
        left: -2.5rem;
        height: 5rem;
        width: 9.75rem;
        z-index: 20;
    }

    #element-78 {
        top: 2.5rem;
        left: 34.6875rem;
        height: 40.0625rem;
        width: 27.5625rem;
        z-index: 4;
    }

    #element-78 .shape {
        border: 0;
        border-radius: 0 0 0 0;
        background: rgb(247, 247, 247);
        box-shadow: rgba(93, 93, 93, 0.12) 0 0 10px 2px;
    }

    #element-73 {
        top: 5.25rem;
        left: 34.6875rem;
        height: 2.375rem;
        width: 27.5625rem;
        z-index: 5;
        color: #37465A;
        font-size: 1.4861rem;
        line-height: 2.4rem;
        text-align: center;
    }

    #element-73 .x_190a7a83 {
        text-align: center;
        line-height: 2.375rem;
        font-size: 1.4861rem;
    }

    #element-73 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-76 {
        top: 8.875rem;
        left: 39.125rem;
        height: 20.4375rem;
        width: 18.75rem;
        z-index: 6;
    }

    .notification-text {
        font-size: 3.125rem;
    }

    .form {
        font-size: 0.8125rem;
    }

    .form-input {
        font-size: 0.9375rem;
        height: 2.6875rem;
    }

    .form-textarea {
        height: 6.25rem;
    }

    .form-label-title {
        margin: 0.3125rem 0 0.5rem;
        font-size: 0.89375rem;
        padding: 0;
        line-height: 1.1875rem;
    }

    .form-multiple-label {
        margin-bottom: 0.625rem;
        font-size: 0.9375rem;
        line-height: 1.1875rem;
        padding: 0;
    }

    .form-multiple-label:empty {
        display: inline;
    }

    .form-checkbox-label:after {
        top: 0.1rem;
    }

    .form-label-outside {
        margin-bottom: 0;
    }

    .form-multiple-label:before {
        transition: background-color 0.1s, border 0.1s;
    }

    .form-radio-label:hover:before {
        border: 0.3125rem solid #9bc7fd;
    }

    .form-messagebox {
        transform: translate(0);
        display: flex;
    }

    .form-messagebox-left {
        transform: translateX(-100%);
        left: -0.625rem;
    }

    .form-messagebox-right {
        transform: translateX(100%);
        right: -0.625rem;
    }

    .form-messagebox:before {
        top: calc(50% - 0.1875rem);
        left: auto;
    }

    .form-messagebox-left:before {
        right: -0.1875rem;
    }

    .form-messagebox-right:before {
        left: -0.1875rem;
    }

    #element-76 .btn.btn-effect3d:active {
        box-shadow: none;
    }

    #element-76 .btn:hover {
        background: #EF442F;
        color: #FFFFFF;
    }

    #element-76 .btn {
        background: #EF442F;
        color: #FFFFFF;
        font-size: 1.2384rem;
        font-weight: 700;
        height: 3.6875rem;
        width: 18.75rem;
        border-radius: 0;
    }

    #element-76 .form-label {
        color: #333333;
    }

    #element-76 ::placeholder {
        color: #333333;
    }

    #element-76 .form-input {
        color: #000000;
        background-color: #FFFFFF;
        border-color: #A3BAC6;
    }

    #element-76 .form-select[aria-invalid="false"] {
        color: #000000;
    }

    #element-76 .user-invalid {
        border-color: #E12627;
    }

    #element-76 input::placeholder,
    #element-76 .form-label-inside {
        color: #333333;
    }

    #element-76 select.valid {
        color: #000000;
    }

    #element-76 .form-btn-geometry {
        top: 22rem;
        left: -0.125rem;
        height: 3.6875rem;
        width: 18.75rem;
        z-index: 6;
    }

    #element-173 {
        top: 10rem;
        left: 0.25rem;
        height: 1.375rem;
        width: 24.5625rem;
        z-index: 45;
        color: #37465A;
        font-size: 0.9907rem;
        line-height: 1.4rem;
        text-align: left;
        font-weight: 400;
    }

    #element-173 .x_0a34168d {
        text-align: left;
        line-height: 1.375rem;
        font-size: 0.9907rem;
    }

    #element-173 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-173 strong {
        font-weight: 700;
    }

    #element-173.headline {
        font-weight: 400;
    }

    #element-172 {
        top: 11.25rem;
        left: -2.5rem;
        height: 1.3125rem;
        width: 2.3125rem;
        z-index: 44;
    }

    #element-172 .shape {
        border-bottom: 1px solid #333333;
    }

    #element-23 {
        top: 13.3125rem;
        left: -2.5rem;
        height: 7.125rem;
        width: 27.3125rem;
        z-index: 7;
        color: #37465A;
        font-size: 2.5387rem;
        line-height: 1rem !important;
        text-align: left;
    }

    #element-23 .x_1ea61c39 {
        text-align: left;
        font-size: 1.4rem;
    }

    #element-23 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-24 {
        top: 19rem;
        left: -2.5rem;
        height: 7.25rem;
        width: 24.3125rem;
        z-index: 8;
        color: #37465A;
        font-size: 1.1146rem;
        line-height: 1.8rem;
        text-align: left;
    }

    #element-24 .x_8ae9aa76 {
        text-align: left;
        line-height: 1.8125rem;
        font-size: 1.1146rem;
    }

    #element-24 .x_93908647 {
        color: #333333;
    }

    #element-209 {
        top: 35.875rem;
        left: 39.75rem;
        height: 3.9375rem;
        width: 17.25rem;
        z-index: 49;
        color: #37465A;
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: center;
    }

    #element-209 .x_70ee4658 {
        text-align: center;
        line-height: 1.3125rem;
        font-size: 0.805rem;
    }

    #element-209 .x_93908647 {
        color: #333333;
    }

    #page-block-ciascqo1yf {
        height: 49.5625rem;
        max-width: 100%;
    }

    #page-block-ciascqo1yf .section-holder-border {
        border: 0;
    }

    #page-block-ciascqo1yf .section-block {
        background: rgb(29, 29, 40);
        height: 49.5625rem;
    }

    #page-block-ciascqo1yf .section-holder-overlay {
        display: none;
    }

    #element-34 {
        top: 4.9375rem;
        left: 9.5625rem;
        height: 6.125rem;
        width: 40.875rem;
        z-index: 9;
        color: #37465A;
        font-size: 2.1672rem;
        line-height: 3.0625rem;
        text-align: center;
    }

    #element-34 .x_ab3dc331 {
        text-align: center;
        line-height: 3.0625rem;
        font-size: 2.1672rem;
    }

    #element-34 .x_f2074b6c {
        color: #ffffff;
    }

    #element-162 {
        top: 14.5rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 41;
    }

    #element-51 {
        top: 14.6875rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 16.5625rem;
        z-index: 40;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-51 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-51 .x_f2074b6c {
        color: #ffffff;
    }

    #element-190 {
        top: 17.1875rem;
        left: -4.375rem;
        height: 21.75rem;
        width: 37.75rem;
        z-index: 21;
    }

    #element-190 .shape {
        border: 0.0625rem solid #A3BAC6;
        background: rgb(240, 243, 245);
        opacity: 0.26;
    }

    #element-135 {
        top: 18.0625rem;
        left: -3.625rem;
        height: 20rem;
        width: 36.25rem;
        z-index: 22;
    }

    #element-157 {
        top: 18.9375rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 33;
    }

    #element-58 {
        top: 19.1875rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 16.5625rem;
        z-index: 32;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-58 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-58 .x_f2074b6c {
        color: #ffffff;
    }

    #element-159 {
        top: 23.0625rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 35;
    }

    #element-44 {
        top: 23.3125rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 16.5625rem;
        z-index: 34;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-44 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-44 .x_f2074b6c {
        color: #ffffff;
    }

    #element-160 {
        top: 27.4375rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 37;
    }

    #element-41 {
        top: 27.6875rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 16.5625rem;
        z-index: 36;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-41 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-41 .x_f2074b6c {
        color: #ffffff;
    }

    #element-161 {
        top: 32rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 39;
    }

    #element-65 {
        top: 32.25rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 20.3125rem;
        z-index: 38;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-65 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-65 .x_f2074b6c {
        color: #ffffff;
    }

    #element-163 {
        top: 36.5625rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 43;
    }

    #element-38 {
        top: 36.75rem;
        left: 41.3125rem;
        height: 1.75rem;
        width: 21.75rem;
        z-index: 42;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-38 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-38 .x_f2074b6c {
        color: #ffffff;
    }

    #element-208 {
        top: 41.125rem;
        left: 37.8125rem;
        height: 2.1875rem;
        width: 2.1875rem;
        z-index: 48;
    }

    #element-207 {
        top: 41.3125rem;
        left: 41.25rem;
        height: 1.75rem;
        width: 26.75rem;
        z-index: 47;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: left;
    }

    #element-207 .x_b558b569 {
        text-align: left;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-207 .x_f2074b6c {
        color: #ffffff;
    }

    #page-block-ajyyc6vf2hc {
        height: 33rem;
        max-width: 100%;
    }

    #page-block-ajyyc6vf2hc .section-holder-border {
        border: 0;
    }

    #page-block-ajyyc6vf2hc .section-block {
        background: rgb(247, 247, 247);
        height: 33rem;
    }

    #page-block-ajyyc6vf2hc .section-holder-overlay {
        display: none;
    }

    #element-123 {
        top: 5.25rem;
        left: 6.8125rem;
        height: 6.125rem;
        width: 46.3125rem;
        z-index: 19;
        color: #37465A;
        font-size: 2.1672rem;
        line-height: 3.0625rem;
        text-align: center;
    }

    #element-123 .x_ab3dc331 {
        text-align: center;
        line-height: 3.0625rem;
        font-size: 2.1672rem;
    }

    #element-123 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-151 {
        top: 14.8125rem;
        left: 4.125rem;
        height: 3.6875rem;
        width: 3.6875rem;
        z-index: 31;
    }

    #element-4 {
        top: 19.75rem;
        left: -1.9375rem;
        height: 1.75rem;
        width: 15.875rem;
        z-index: 29;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: center;
    }

    #element-4 .x_7df451b3 {
        text-align: center;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-4 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-5 {
        top: 22.25rem;
        left: -2.5rem;
        height: 5.0625rem;
        width: 17rem;
        z-index: 30;
        color: #37465A;
        font-size: 1.0526rem;
        line-height: 1.7rem;
        text-align: center;
    }

    #element-5 .x_7a88b5a4 {
        text-align: center;
        line-height: 1.6875rem;
        font-size: 1.0526rem;
    }

    #element-5 .x_93908647 {
        color: #333333;
    }

    #element-150 {
        top: 14.8125rem;
        left: 28rem;
        height: 4rem;
        width: 4rem;
        z-index: 28;
    }

    #element-7 {
        top: 19.8125rem;
        left: 22.5625rem;
        height: 1.75rem;
        width: 14.875rem;
        z-index: 26;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: center;
    }

    #element-7 .x_7df451b3 {
        text-align: center;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-7 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-8 {
        top: 22.25rem;
        left: 19.5625rem;
        height: 5.0625rem;
        width: 20.875rem;
        z-index: 27;
        color: #37465A;
        font-size: 1.0526rem;
        line-height: 1.7rem;
        text-align: center;
    }

    #element-8 .x_7a88b5a4 {
        text-align: center;
        line-height: 1.6875rem;
        font-size: 1.0526rem;
    }

    #element-8 .x_93908647 {
        color: #333333;
    }

    #element-149 {
        top: 14.8125rem;
        left: 52.125rem;
        height: 4rem;
        width: 4rem;
        z-index: 25;
    }

    #element-10 {
        top: 19.8125rem;
        left: 46.3125rem;
        height: 1.75rem;
        width: 15.625rem;
        z-index: 23;
        color: #37465A;
        font-size: 1.2384rem;
        line-height: 1.75rem;
        text-align: center;
    }

    #element-10 .x_7df451b3 {
        text-align: center;
        line-height: 1.75rem;
        font-size: 1.2384rem;
    }

    #element-10 .x_f929cec8 {
        color: #1d1d28;
    }

    #element-11 {
        top: 22.3125rem;
        left: 45.8125rem;
        height: 3.375rem;
        width: 16.625rem;
        z-index: 24;
        color: #37465A;
        font-size: 1.0526rem;
        line-height: 1.7rem;
        text-align: center;
    }

    #element-11 .x_7a88b5a4 {
        text-align: center;
        line-height: 1.6875rem;
        font-size: 1.0526rem;
    }

    #element-11 .x_93908647 {
        color: #333333;
    }

    #page-block-9umqgxs20o {
        height: 28.9375rem;
        max-width: 100%;
    }

    #page-block-9umqgxs20o .section-holder-border {
        border: 0;
    }

    #page-block-9umqgxs20o .section-block {
        background: repeat rgb(250, 250, 250) 50% 50% / cover;
        background-image: url(//v.fastcdn.co/u/e36f2c7e/62346883-0-vision-bg.d0fcbe3c.jpg), url(//v.fastcdn.co/t/e36f2c7e/803aaa74/1706403743-62346883-ghost-vision-bg.d0fcbe3c.jpg);
        height: 28.9375rem;
    }

    #page-block-9umqgxs20o .section-holder-overlay {
        display: none;
    }

    #element-205 {
        top: 0;
        left: 45.5rem;
        height: 28.875rem;
        width: 13.25rem;
        z-index: 46;
    }

    #element-70 {
        top: 5.25rem;
        left: -2.875rem;
        height: 7.125rem;
        width: 41.8125rem;
        z-index: 13;
        color: #37465A;
        font-size: 2.5387rem;
        line-height: 3.5875rem;
        text-align: left;
    }

    #element-70 .x_1ea61c39 {
        text-align: left;
        line-height: 3.5625rem;
        font-size: 2.5387rem;
    }

    #element-70 .x_f2074b6c {
        color: #ffffff;
    }

    #element-71 {
        top: 13.125rem;
        left: -2.875rem;
        height: 3.625rem;
        width: 36.5rem;
        z-index: 14;
        color: #37465A;
        font-size: 1.1146rem;
        line-height: 1.8rem;
        text-align: left;
    }

    #element-71 .x_8c071feb {
        text-align: left;
        line-height: 1.8125rem;
        font-size: 1.1146rem;
    }

    #element-71 .x_f2074b6c {
        color: #ffffff;
    }

    #element-74 {
        top: 19.1875rem;
        left: -2.875rem;
        height: 3.6875rem;
        width: 18.625rem;
        z-index: 12;
    }

    #element-74 .btn.btn-effect3d:active {
        box-shadow: none;
    }

    #element-74 .btn:hover {
        background: #6C30DD;
        color: #FFFFFF;
    }

    #element-74 .btn {
        background: #7B4FCD;
        color: #FFFFFF;
        font-size: 1.2384rem;
        font-weight: 700;
        font-style: normal;
        height: 3.6875rem;
        width: 18.625rem;
        border-radius: 0;
    }

    #page-block-3fteat1nlsk {
        height: 17.625rem;
        max-width: 100%;
    }

    #page-block-3fteat1nlsk .section-holder-border {
        border: 0;
    }

    #page-block-3fteat1nlsk .section-block {
        background: rgb(29, 29, 40);
        height: 17.625rem;
    }

    #page-block-3fteat1nlsk .section-holder-overlay {
        display: none;
    }

    #element-85 {
        top: 3.1875rem;
        left: -2.875rem;
        height: 1.5625rem;
        width: 8.25rem;
        z-index: 10;
        color: #37465A;
        font-size: 1.1146rem;
        line-height: 1.575rem;
        text-align: left;
    }

    #element-85 .x_9a9d3d17 {
        text-align: left;
        line-height: 1.5625rem;
        font-size: 1.1146rem;
    }

    #element-85 .x_f2074b6c {
        color: #ffffff;
    }

    #element-86 {
        top: 5.4375rem;
        left: -2.875rem;
        height: 3.25rem;
        width: 16.375rem;
        z-index: 11;
        color: #37465A;
        font-size: 0.9907rem;
        line-height: 1.6rem;
        text-align: left;
    }

    #element-86 .x_fae6260b {
        text-align: left;
        line-height: 1.625rem;
        font-size: 0.9907rem;
    }

    #element-86 .x_50f304a1 {
        color: #9e9e9e;
    }

    #element-87 {
        top: 3.1875rem;
        left: 20.5625rem;
        height: 1.5625rem;
        width: 4.875rem;
        z-index: 15;
        color: #37465A;
        font-size: 1.1146rem;
        line-height: 1.575rem;
        text-align: left;
    }

    #element-87 .x_9a9d3d17 {
        text-align: left;
        line-height: 1.5625rem;
        font-size: 1.1146rem;
    }

    #element-87 .x_f2074b6c {
        color: #ffffff;
    }

    #element-88 {
        top: 5.5rem;
        left: 20.5625rem;
        height: 1.625rem;
        width: 8.4375rem;
        z-index: 16;
        color: #37465A;
        font-size: 0.9907rem;
        line-height: 1.6rem;
        text-align: left;
    }

    #element-88 .x_fae6260b {
        text-align: left;
        line-height: 1.625rem;
        font-size: 0.9907rem;
    }

    #element-88 .x_50f304a1 {
        color: #9e9e9e;
    }

    #element-89 {
        top: 3.1875rem;
        left: 35.1875rem;
        height: 1.5625rem;
        width: 3.5625rem;
        z-index: 17;
        color: #37465A;
        font-size: 1.1146rem;
        line-height: 1.575rem;
        text-align: left;
    }

    #element-89 .x_9a9d3d17 {
        text-align: left;
        line-height: 1.5625rem;
        font-size: 1.1146rem;
    }

    #element-89 .x_f2074b6c {
        color: #ffffff;
    }

    #element-90 {
        top: 5.5rem;
        left: 35.1875rem;
        height: 1.625rem;
        width: 7.1875rem;
        z-index: 18;
        color: #37465A;
        font-size: 0.9907rem;
        line-height: 1.6rem;
        text-align: left;
    }

    #element-90 .x_fae6260b {
        text-align: left;
        line-height: 1.625rem;
        font-size: 0.9907rem;
    }

    #element-90 .x_50f304a1 {
        color: #9e9e9e;
    }

    #element-84 {
        top: 9.625rem;
        left: -2.875rem;
        height: 6.25rem;
        width: 37.1875rem;
        z-index: 3;
    }
}

/*Dynamic Copyright*/
#newDate {
    font-size: 16px !important;
    color: #9e9e9e !important;
    text-align: left;
    margin-top: 50px;
}

/*Confirmation text*/
.notification-text {
    font-size: 20px !important;
    color: #ffffff !important;
    text-align: center !important;
}

@media (max-width: 576px) {
    .notification-text {
        margin-left: 50px !important;
        margin-right: 50px !important;
        font-size: 18px !important;
    }
}

/* Button */
.btn {
    -webkit-transition: all .5s ease-out;
    -moz-transition: all .5s ease-out;
    -ms-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
}


@media(max-width:1199px) {
    .mean-container .mean-nav ul li#menu-item-5387 {
        padding: 10px 25px;
    }

    .mean-container .mean-nav ul li#menu-item-5387 {
        text-align: center;
    }

    #menu-item-4681.menu-item-4681 {
        margin-left: 0;
    }
}

@media(max-width:767px) {
    .footer-widget p {
        margin-bottom: 0;
    }
}

.elementor-widget-image {
    text-align: center
}

.elementor-widget-image a {
    display: inline-block
}

.elementor-widget-image a img[src$=".svg"] {
    width: 48px
}

.elementor-widget-image img {
    vertical-align: middle;
    display: inline-block
}

.elementor-element-24fa316 {
    background: linear-gradient(151.36deg, #493D6C 17.66%, #3C2D54 85.8%);
    padding: 60px 0;
    text-align: center;
}

.elementor-element-24fa316 .elementor-widget-heading h2 {
    color: #ffffff;

    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
}

.elementor-4586 .elementor-element.elementor-element-4e0fa4c {
    column-gap: 0px;
    text-align: center;
    width: 100%-15px;
    max-width: 100%-15px;
    align-self: flex-end;
}

.elementor-element-24fa316 .elementor-widget-text-editor p {

    font-size: 20px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em;
    color: #ffffff;
    margin: 10px auto 30px;
}

.elementor-4586 .elementor-element.elementor-element-579e281 {
    text-align: center;
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-100,
    .elementor-column[data-col="100"] {
        width: 100%;
    }
}

.elementor-section .elementor-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
}

.elementor img {
    height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.elementor-widget-image img {
    vertical-align: middle;
    display: inline-block;
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 10px;
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: "-webkit-box";
    display: "-ms-flexbox";
    display: flex;
}

@media (min-width: 768px) {

    .elementor-column.elementor-col-25,
    .elementor-column[data-col="25"] {
        width: 25%;
    }
}

.elementor-column,
.elementor-column-wrap {
    position: relative;
    display: flex;
}

.elementor-element-bf8d60b .elementor-widget-heading h2 {
    font-size: 46px;
    font-weight: 600;
    color: #D3D3D3;
    margin-bottom: 10px;
    text-align: center;
}

.elementor-element-bf8d60b .elementor-element.elementor-widget-image {
    background: #ef442f;
    width: max-content;
    padding: 28px;
    border-radius: 50%;
    margin: 0 auto;
}

.elementor-element-bf8d60b .elementor-widget:not(:last-child) {
    margin-bottom: 10px;
}

.elementor-widget-image {
    text-align: center;
}

.elementor-widget {
    position: relative;
}

.elementor-4586 .elementor-element.elementor-element-c1ea3b7 {
    text-align: center;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.elementor-section {
    position: relative;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1;
}

.elementor-element .elementor-widget-container {
    transition: " background .3s, border .3s, border-radius .3s, box-shadow .3s, -webkit-border-radius .3s, -webkit-box-shadow .3s";
}

.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1
}

.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a {
    color: inherit;
    font-size: inherit;
    line-height: inherit
}

.elementor-widget-heading .elementor-heading-title.elementor-size-small {
    font-size: 15px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
    font-size: 19px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-large {
    font-size: 29px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
    font-size: 39px
}

.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
    font-size: 59px
}

.elementor-4586 .elementor-element.elementor-element-3d1c75b {
    text-align: center;
}

.elementor-element-bf8d60b .elementor-widget:not(:last-child) {
    margin-bottom: 10px;
}

.elementor-widget-wrap>.elementor-element {
    width: 100%;
}

.elementor-widget {
    position: relative;
}

.elementor-4586 .elementor-element.elementor-element-a4cd349 .elementor-heading-title {
    font-weight: 500;
}

.elementor-element-bf8d60b .elementor-widget-heading h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000d47;
    line-height: 25px;
    margin: 20px auto 0;
}

.elementor-4586 .elementor-element.elementor-element-a4cd349 {
    text-align: center;
}

.elementor-element-bf8d60b .elementor-widget-text-editor p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
}

.elementor-element-bf8d60b .elementor-widget-heading h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000d47;
    line-height: 25px;
    margin: 20px auto 0;
    text-align: center;
}

.elementor-4586 .elementor-element.elementor-element-a4cd349 .elementor-heading-title {
    font-weight: 500;
}

@media screen and (min-width: 768px) {
    #element-173 .x_0a34168d {
        text-align: left;
        line-height: 1.375rem;
        font-size: 0.9907rem;
    }
}

.elementor-4586 .elementor-element.elementor-element-7c5544f .elementor-heading-title {
    font-size: 38px;
    font-weight: 600;
}

.elementor-heading-title {
    padding: 0;
    margin: 0;
    line-height: 1;
}