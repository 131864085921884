/* Loaders CSS */
.ball-pulse-sync>div,
.ball-pulse>div,
.ball-scale-random>div,
.ball-scale>div {
    background-color: #fff;
    border-radius: 100%;
    margin: 2px;
    display: inline-block
}

@-webkit-keyframes scale {

    0%,
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    45% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: .7
    }
}

@keyframes scale {

    0%,
    80% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    45% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: .7
    }
}

.ball-pulse>div:nth-child(1) {
    -webkit-animation: scale .75s -.24s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: scale .75s -.24s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.ball-pulse>div:nth-child(2) {
    -webkit-animation: scale .75s -.12s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: scale .75s -.12s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.ball-pulse>div:nth-child(3) {
    -webkit-animation: scale .75s 0s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: scale .75s 0s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.ball-pulse>div {
    width: 15px;
    height: 15px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

.ball-pulse-sync>div:nth-child(1) {
    -webkit-animation: ball-pulse-sync .6s -.14s infinite ease-in-out;
    animation: ball-pulse-sync .6s -.14s infinite ease-in-out
}

.ball-pulse-sync>div:nth-child(2) {
    -webkit-animation: ball-pulse-sync .6s -70ms infinite ease-in-out;
    animation: ball-pulse-sync .6s -70ms infinite ease-in-out
}

.ball-pulse-sync>div:nth-child(3) {
    -webkit-animation: ball-pulse-sync .6s 0s infinite ease-in-out;
    animation: ball-pulse-sync .6s 0s infinite ease-in-out
}

.ball-pulse-sync>div {
    width: 15px;
    height: 15px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes ball-scale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes ball-scale {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

.ball-scale>div {
    height: 60px;
    width: 60px;
    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite
}

.ball-scale-random {
    width: 37px;
    height: 40px
}

.ball-scale-random>div {
    position: absolute;
    height: 30px;
    width: 30px;
    -webkit-animation: ball-scale 1s 0s ease-in-out infinite;
    animation: ball-scale 1s 0s ease-in-out infinite
}

.ball-rotate,
.ball-rotate>div {
    position: relative
}

.ball-rotate>div,
.ball-rotate>div:after,
.ball-rotate>div:before {
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 100%
}

.ball-scale-random>div:nth-child(1) {
    margin-left: -7px;
    -webkit-animation: ball-scale 1s .2s ease-in-out infinite;
    animation: ball-scale 1s .2s ease-in-out infinite
}

.ball-scale-random>div:nth-child(3) {
    margin-left: -2px;
    margin-top: 9px;
    -webkit-animation: ball-scale 1s .5s ease-in-out infinite;
    animation: ball-scale 1s .5s ease-in-out infinite
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.ball-rotate>div {
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.ball-rotate>div:first-child {
    -webkit-animation: rotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
    animation: rotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite
}

.ball-rotate>div:after,
.ball-rotate>div:before {
    margin: 2px;
    content: "";
    position: absolute;
    opacity: .8
}

.ball-rotate>div:before {
    top: 0;
    left: -28px
}

.ball-rotate>div:after {
    top: 0;
    left: 25px
}

.ball-clip-rotate>div {
    border-radius: 100%;
    margin: 2px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    height: 26px;
    width: 26px;
    background: 0 0 !important;
    display: inline-block;
    -webkit-animation: rotate .75s 0s linear infinite;
    animation: rotate .75s 0s linear infinite
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes scale {
    30% {
        -webkit-transform: scale(.3);
        transform: scale(.3)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.ball-clip-rotate-pulse {
    position: relative;
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
}

.ball-clip-rotate-pulse>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100%
}

.ball-clip-rotate-pulse>div:first-child {
    background: #fff;
    height: 16px;
    width: 16px;
    top: 7px;
    left: -7px;
    -webkit-animation: scale 1s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: scale 1s 0s cubic-bezier(.09, .57, .49, .9) infinite
}

.ball-clip-rotate-pulse>div:last-child {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -16px;
    top: -2px;
    background: 0 0;
    border: 2px solid;
    border-color: #fff transparent;
    -webkit-animation: rotate 1s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: rotate 1s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    -webkit-animation-duration: 1s;
    animation-duration: 1s
}

.ball-beat>div,
.ball-scale-multiple>div {
    background-color: #fff;
    border-radius: 100%
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1)
    }

    50% {
        -webkit-transform: rotate(180deg) scale(.6);
        transform: rotate(180deg) scale(.6)
    }

    100% {
        -webkit-transform: rotate(360deg) scale(1);
        transform: rotate(360deg) scale(1)
    }
}

.ball-clip-rotate-multiple {
    position: relative
}

.ball-clip-rotate-multiple>div {
    position: absolute;
    left: -20px;
    top: -20px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 100%;
    height: 35px;
    width: 35px;
    -webkit-animation: rotate 1s 0s ease-in-out infinite;
    animation: rotate 1s 0s ease-in-out infinite
}

.ball-clip-rotate-multiple>div:last-child {
    display: inline-block;
    top: -10px;
    left: -10px;
    width: 15px;
    height: 15px;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    border-color: #fff transparent;
    -webkit-animation-direction: reverse;
    animation-direction: reverse
}

@-webkit-keyframes ball-scale-ripple {
    0% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: 1
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .7
    }

    100% {
        opacity: 0
    }
}

@keyframes ball-scale-ripple {
    0% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: 1
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .7
    }

    100% {
        opacity: 0
    }
}

.ball-scale-ripple>div {
    height: 50px;
    width: 50px;
    border-radius: 100%;
    border: 2px solid #fff;
    -webkit-animation: ball-scale-ripple 1s 0s infinite cubic-bezier(.21, .53, .56, .8);
    animation: ball-scale-ripple 1s 0s infinite cubic-bezier(.21, .53, .56, .8)
}

@-webkit-keyframes ball-scale-ripple-multiple {
    0% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: 1
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .7
    }

    100% {
        opacity: 0
    }
}

@keyframes ball-scale-ripple-multiple {
    0% {
        -webkit-transform: scale(.1);
        transform: scale(.1);
        opacity: 1
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: .7
    }

    100% {
        opacity: 0
    }
}

.ball-scale-ripple-multiple {
    position: relative;
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px)
}

.ball-scale-ripple-multiple>div:nth-child(0) {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

.ball-scale-ripple-multiple>div:nth-child(1) {
    -webkit-animation-delay: -.6s;
    animation-delay: -.6s
}

.ball-scale-ripple-multiple>div:nth-child(2) {
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.ball-scale-ripple-multiple>div:nth-child(3) {
    -webkit-animation-delay: -.2s;
    animation-delay: -.2s
}

.ball-scale-ripple-multiple>div {
    position: absolute;
    top: -2px;
    left: -26px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid #fff;
    -webkit-animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(.21, .53, .56, .8);
    animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(.21, .53, .56, .8)
}

@-webkit-keyframes ball-beat {
    50% {
        opacity: .2;
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes ball-beat {
    50% {
        opacity: .2;
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.ball-beat>div {
    width: 15px;
    height: 15px;
    margin: 2px;
    display: inline-block;
    -webkit-animation: ball-beat .7s 0s infinite linear;
    animation: ball-beat .7s 0s infinite linear
}

.ball-beat>div:nth-child(2n-1) {
    -webkit-animation-delay: -.35s !important;
    animation-delay: -.35s !important
}

@-webkit-keyframes ball-scale-multiple {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    5% {
        opacity: 1
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes ball-scale-multiple {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    5% {
        opacity: 1
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

.ball-scale-multiple {
    position: relative;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px)
}

.ball-scale-multiple>div:nth-child(2) {
    -webkit-animation-delay: -.4s;
    animation-delay: -.4s
}

.ball-scale-multiple>div:nth-child(3) {
    -webkit-animation-delay: -.2s;
    animation-delay: -.2s
}

.ball-scale-multiple>div {
    position: absolute;
    left: -30px;
    top: 0;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
    animation: ball-scale-multiple 1s 0s linear infinite
}

.ball-triangle-path>div:nth-child(1),
.ball-triangle-path>div:nth-child(2) {
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite
}

@-webkit-keyframes ball-triangle-path-1 {
    33% {
        -webkit-transform: translate(25px, -50px);
        transform: translate(25px, -50px)
    }

    66% {
        -webkit-transform: translate(50px, 0);
        transform: translate(50px, 0)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-triangle-path-1 {
    33% {
        -webkit-transform: translate(25px, -50px);
        transform: translate(25px, -50px)
    }

    66% {
        -webkit-transform: translate(50px, 0);
        transform: translate(50px, 0)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@-webkit-keyframes ball-triangle-path-2 {
    33% {
        -webkit-transform: translate(25px, 50px);
        transform: translate(25px, 50px)
    }

    66% {
        -webkit-transform: translate(-25px, 50px);
        transform: translate(-25px, 50px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-triangle-path-2 {
    33% {
        -webkit-transform: translate(25px, 50px);
        transform: translate(25px, 50px)
    }

    66% {
        -webkit-transform: translate(-25px, 50px);
        transform: translate(-25px, 50px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@-webkit-keyframes ball-triangle-path-3 {
    33% {
        -webkit-transform: translate(-50px, 0);
        transform: translate(-50px, 0)
    }

    66% {
        -webkit-transform: translate(-25px, -50px);
        transform: translate(-25px, -50px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-triangle-path-3 {
    33% {
        -webkit-transform: translate(-50px, 0);
        transform: translate(-50px, 0)
    }

    66% {
        -webkit-transform: translate(-25px, -50px);
        transform: translate(-25px, -50px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

.ball-triangle-path {
    position: relative;
    -webkit-transform: translate(-29.99px, -37.51px);
    transform: translate(-29.99px, -37.51px)
}

.ball-triangle-path>div:nth-child(1) {
    -webkit-animation-name: ball-triangle-path-1;
    animation-name: ball-triangle-path-1;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite
}

.ball-triangle-path>div:nth-child(2) {
    -webkit-animation-name: ball-triangle-path-2;
    animation-name: ball-triangle-path-2;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite
}

.ball-triangle-path>div:nth-child(3) {
    -webkit-animation-name: ball-triangle-path-3;
    animation-name: ball-triangle-path-3;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.ball-triangle-path>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #fff
}

.ball-grid-beat>div,
.ball-pulse-rise>div {
    -webkit-animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite
}

.ball-grid-beat>div,
.ball-grid-pulse>div,
.ball-pulse-rise>div {
    height: 15px;
    border-radius: 100%;
    display: inline-block;
    background-color: #fff;
    margin: 2px
}

.ball-triangle-path>div:nth-of-type(1) {
    top: 50px
}

.ball-triangle-path>div:nth-of-type(2) {
    left: 25px
}

.ball-triangle-path>div:nth-of-type(3) {
    top: 50px;
    left: 50px
}

@-webkit-keyframes ball-pulse-rise-even {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    25% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    50% {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    75% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes ball-pulse-rise-even {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    25% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    50% {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    75% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes ball-pulse-rise-odd {
    0% {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    25% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    75% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
}

@keyframes ball-pulse-rise-odd {
    0% {
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    25% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px)
    }

    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }

    75% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform: scale(.75);
        transform: scale(.75)
    }
}

.ball-pulse-rise>div {
    width: 15px;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: cubic-bezier(.15, .46, .9, .6);
    animation-timing-function: cubic-bezier(.15, .46, .9, .6);
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

.ball-pulse-rise>div:nth-child(2n) {
    -webkit-animation-name: ball-pulse-rise-even;
    animation-name: ball-pulse-rise-even
}

.ball-pulse-rise>div:nth-child(2n-1) {
    -webkit-animation-name: ball-pulse-rise-odd;
    animation-name: ball-pulse-rise-odd
}

@-webkit-keyframes ball-grid-beat {
    50% {
        opacity: .7
    }

    100% {
        opacity: 1
    }
}

@keyframes ball-grid-beat {
    50% {
        opacity: .7
    }

    100% {
        opacity: 1
    }
}

.ball-grid-beat {
    width: 57px
}

.ball-grid-beat>div:nth-child(1) {
    -webkit-animation-delay: .49s;
    animation-delay: .49s;
    -webkit-animation-duration: .93s;
    animation-duration: .93s
}

.ball-grid-beat>div:nth-child(2) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
    -webkit-animation-duration: 1.02s;
    animation-duration: 1.02s
}

.ball-grid-beat>div:nth-child(3) {
    -webkit-animation-delay: .63s;
    animation-delay: .63s;
    -webkit-animation-duration: 1.07s;
    animation-duration: 1.07s
}

.ball-grid-beat>div:nth-child(4) {
    -webkit-animation-delay: -40ms;
    animation-delay: -40ms;
    -webkit-animation-duration: .75s;
    animation-duration: .75s
}

.ball-grid-beat>div:nth-child(5) {
    -webkit-animation-delay: .21s;
    animation-delay: .21s;
    -webkit-animation-duration: 1.41s;
    animation-duration: 1.41s
}

.ball-grid-beat>div:nth-child(6) {
    -webkit-animation-delay: -80ms;
    animation-delay: -80ms;
    -webkit-animation-duration: .66s;
    animation-duration: .66s
}

.ball-grid-beat>div:nth-child(7) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 1.36s;
    animation-duration: 1.36s
}

.ball-grid-beat>div:nth-child(8) {
    -webkit-animation-delay: .78s;
    animation-delay: .78s;
    -webkit-animation-duration: 1.17s;
    animation-duration: 1.17s
}

.ball-grid-beat>div:nth-child(9) {
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
    -webkit-animation-duration: 1.49s;
    animation-duration: 1.49s
}

.ball-grid-beat>div {
    width: 15px;
    animation-fill-mode: both;
    float: left;
    -webkit-animation-name: ball-grid-beat;
    animation-name: ball-grid-beat;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

@-webkit-keyframes ball-grid-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: .7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes ball-grid-pulse {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: .7
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

.ball-grid-pulse {
    width: 57px
}

.ball-grid-pulse>div:nth-child(1) {
    -webkit-animation-delay: .19s;
    animation-delay: .19s;
    -webkit-animation-duration: .98s;
    animation-duration: .98s
}

.ball-grid-pulse>div:nth-child(2) {
    -webkit-animation-delay: .48s;
    animation-delay: .48s;
    -webkit-animation-duration: 1.58s;
    animation-duration: 1.58s
}

.ball-grid-pulse>div:nth-child(3) {
    -webkit-animation-delay: .58s;
    animation-delay: .58s;
    -webkit-animation-duration: 1.13s;
    animation-duration: 1.13s
}

.ball-grid-pulse>div:nth-child(4) {
    -webkit-animation-delay: .63s;
    animation-delay: .63s;
    -webkit-animation-duration: .98s;
    animation-duration: .98s
}

.ball-grid-pulse>div:nth-child(5) {
    -webkit-animation-delay: 60ms;
    animation-delay: 60ms;
    -webkit-animation-duration: .64s;
    animation-duration: .64s
}

.ball-grid-pulse>div:nth-child(6) {
    -webkit-animation-delay: .38s;
    animation-delay: .38s;
    -webkit-animation-duration: .85s;
    animation-duration: .85s
}

.ball-grid-pulse>div:nth-child(7) {
    -webkit-animation-delay: -60ms;
    animation-delay: -60ms;
    -webkit-animation-duration: 1.53s;
    animation-duration: 1.53s
}

.ball-grid-pulse>div:nth-child(8) {
    -webkit-animation-delay: .57s;
    animation-delay: .57s;
    -webkit-animation-duration: .76s;
    animation-duration: .76s
}

.ball-grid-pulse>div:nth-child(9) {
    -webkit-animation-delay: .73s;
    animation-delay: .73s;
    -webkit-animation-duration: .98s;
    animation-duration: .98s
}

.ball-grid-pulse>div {
    width: 15px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    float: left;
    -webkit-animation-name: ball-grid-pulse;
    animation-name: ball-grid-pulse;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

@-webkit-keyframes ball-spin-fade-loader {
    50% {
        opacity: .3;
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes ball-spin-fade-loader {
    50% {
        opacity: .3;
        -webkit-transform: scale(.4);
        transform: scale(.4)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.ball-spin-fade-loader {
    position: relative;
    top: -10px;
    left: -10px
}

.ball-spin-fade-loader>div:nth-child(1) {
    top: 25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -.96s infinite linear;
    animation: ball-spin-fade-loader 1s -.96s infinite linear
}

.ball-spin-fade-loader>div:nth-child(2) {
    top: 17.05px;
    left: 17.05px;
    -webkit-animation: ball-spin-fade-loader 1s -.84s infinite linear;
    animation: ball-spin-fade-loader 1s -.84s infinite linear
}

.ball-spin-fade-loader>div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation: ball-spin-fade-loader 1s -.72s infinite linear;
    animation: ball-spin-fade-loader 1s -.72s infinite linear
}

.ball-spin-fade-loader>div:nth-child(4) {
    top: -17.05px;
    left: 17.05px;
    -webkit-animation: ball-spin-fade-loader 1s -.6s infinite linear;
    animation: ball-spin-fade-loader 1s -.6s infinite linear
}

.ball-spin-fade-loader>div:nth-child(5) {
    top: -25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -.48s infinite linear;
    animation: ball-spin-fade-loader 1s -.48s infinite linear
}

.ball-spin-fade-loader>div:nth-child(6) {
    top: -17.05px;
    left: -17.05px;
    -webkit-animation: ball-spin-fade-loader 1s -.36s infinite linear;
    animation: ball-spin-fade-loader 1s -.36s infinite linear
}

.ball-spin-fade-loader>div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation: ball-spin-fade-loader 1s -.24s infinite linear;
    animation: ball-spin-fade-loader 1s -.24s infinite linear
}

.ball-spin-fade-loader>div:nth-child(8) {
    top: 17.05px;
    left: -17.05px;
    -webkit-animation: ball-spin-fade-loader 1s -.12s infinite linear;
    animation: ball-spin-fade-loader 1s -.12s infinite linear
}

.ball-spin-fade-loader>div,
.ball-spin-loader>div {
    -webkit-animation-fill-mode: both;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%
}

.ball-spin-fade-loader>div {
    background-color: #fff;
    margin: 2px;
    animation-fill-mode: both
}

@-webkit-keyframes ball-spin-loader {
    75% {
        opacity: .2
    }

    100% {
        opacity: 1
    }
}

@keyframes ball-spin-loader {
    75% {
        opacity: .2
    }

    100% {
        opacity: 1
    }
}

.ball-spin-loader {
    position: relative
}

.ball-spin-loader>span:nth-child(1) {
    top: 45px;
    left: 0;
    -webkit-animation: ball-spin-loader 2s .9s infinite linear;
    animation: ball-spin-loader 2s .9s infinite linear
}

.ball-spin-loader>span:nth-child(2) {
    top: 30.68px;
    left: 30.68px;
    -webkit-animation: ball-spin-loader 2s 1.8s infinite linear;
    animation: ball-spin-loader 2s 1.8s infinite linear
}

.ball-spin-loader>span:nth-child(3) {
    top: 0;
    left: 45px;
    -webkit-animation: ball-spin-loader 2s 2.7s infinite linear;
    animation: ball-spin-loader 2s 2.7s infinite linear
}

.ball-spin-loader>span:nth-child(4) {
    top: -30.68px;
    left: 30.68px;
    -webkit-animation: ball-spin-loader 2s 3.6s infinite linear;
    animation: ball-spin-loader 2s 3.6s infinite linear
}

.ball-spin-loader>span:nth-child(5) {
    top: -45px;
    left: 0;
    -webkit-animation: ball-spin-loader 2s 4.5s infinite linear;
    animation: ball-spin-loader 2s 4.5s infinite linear
}

.ball-spin-loader>span:nth-child(6) {
    top: -30.68px;
    left: -30.68px;
    -webkit-animation: ball-spin-loader 2s 5.4s infinite linear;
    animation: ball-spin-loader 2s 5.4s infinite linear
}

.ball-spin-loader>span:nth-child(7) {
    top: 0;
    left: -45px;
    -webkit-animation: ball-spin-loader 2s 6.3s infinite linear;
    animation: ball-spin-loader 2s 6.3s infinite linear
}

.ball-spin-loader>span:nth-child(8) {
    top: 30.68px;
    left: -30.68px;
    -webkit-animation: ball-spin-loader 2s 7.2s infinite linear;
    animation: ball-spin-loader 2s 7.2s infinite linear
}

.ball-spin-loader>div {
    animation-fill-mode: both;
    background: green
}

.ball-zig-zag-deflect>div,
.ball-zig-zag>div {
    background-color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px 2px 2px 15px;
    top: 4px;
    left: -7px
}

@-webkit-keyframes ball-zig {
    33% {
        -webkit-transform: translate(-15px, -30px);
        transform: translate(-15px, -30px)
    }

    66% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-zig {
    33% {
        -webkit-transform: translate(-15px, -30px);
        transform: translate(-15px, -30px)
    }

    66% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@-webkit-keyframes ball-zag {
    33% {
        -webkit-transform: translate(15px, 30px);
        transform: translate(15px, 30px)
    }

    66% {
        -webkit-transform: translate(-15px, 30px);
        transform: translate(-15px, 30px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-zag {
    33% {
        -webkit-transform: translate(15px, 30px);
        transform: translate(15px, 30px)
    }

    66% {
        -webkit-transform: translate(-15px, 30px);
        transform: translate(-15px, 30px)
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

.ball-zig-zag {
    position: relative;
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px)
}

.ball-zig-zag>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute
}

.ball-zig-zag>div:first-child {
    -webkit-animation: ball-zig .7s 0s infinite linear;
    animation: ball-zig .7s 0s infinite linear
}

.ball-zig-zag>div:last-child {
    -webkit-animation: ball-zag .7s 0s infinite linear;
    animation: ball-zag .7s 0s infinite linear
}

@-webkit-keyframes ball-zig-deflect {

    17%,
    84% {
        -webkit-transform: translate(-15px, -30px);
        transform: translate(-15px, -30px)
    }

    34%,
    67% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px)
    }

    100%,
    50% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-zig-deflect {

    17%,
    84% {
        -webkit-transform: translate(-15px, -30px);
        transform: translate(-15px, -30px)
    }

    34%,
    67% {
        -webkit-transform: translate(15px, -30px);
        transform: translate(15px, -30px)
    }

    100%,
    50% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@-webkit-keyframes ball-zag-deflect {

    17%,
    84% {
        -webkit-transform: translate(15px, 30px);
        transform: translate(15px, 30px)
    }

    34%,
    67% {
        -webkit-transform: translate(-15px, 30px);
        transform: translate(-15px, 30px)
    }

    100%,
    50% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

@keyframes ball-zag-deflect {

    17%,
    84% {
        -webkit-transform: translate(15px, 30px);
        transform: translate(15px, 30px)
    }

    34%,
    67% {
        -webkit-transform: translate(-15px, 30px);
        transform: translate(-15px, 30px)
    }

    100%,
    50% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0)
    }
}

.ball-zig-zag-deflect {
    position: relative;
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px)
}

.ball-zig-zag-deflect>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute
}

.line-scale-party>div,
.line-scale-pulse-out-rapid>div,
.line-scale-pulse-out>div,
.line-scale>div {
    width: 4px;
    height: 35px;
    display: inline-block
}

.line-scale-party>div,
.line-scale-pulse-out-rapid>div,
.line-scale-pulse-out>div,
.line-scale>div,
.line-spin-fade-loader>div {
    border-radius: 2px;
    margin: 2px;
    background-color: #fff
}

.ball-zig-zag-deflect>div:first-child {
    -webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
    animation: ball-zig-deflect 1.5s 0s infinite linear
}

.ball-zig-zag-deflect>div:last-child {
    -webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
    animation: ball-zag-deflect 1.5s 0s infinite linear
}

@-webkit-keyframes line-scale {

    0%,
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    50% {
        -webkit-transform: scaley(.4);
        transform: scaley(.4)
    }
}

@keyframes line-scale {

    0%,
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    50% {
        -webkit-transform: scaley(.4);
        transform: scaley(.4)
    }
}

.line-scale>div:nth-child(1) {
    -webkit-animation: line-scale 1s -.4s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: line-scale 1s -.4s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.line-scale>div:nth-child(2) {
    -webkit-animation: line-scale 1s -.3s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: line-scale 1s -.3s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.line-scale>div:nth-child(3) {
    -webkit-animation: line-scale 1s -.2s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: line-scale 1s -.2s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.line-scale>div:nth-child(4) {
    -webkit-animation: line-scale 1s -.1s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: line-scale 1s -.1s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.line-scale>div:nth-child(5) {
    -webkit-animation: line-scale 1s 0s infinite cubic-bezier(.2, .68, .18, 1.08);
    animation: line-scale 1s 0s infinite cubic-bezier(.2, .68, .18, 1.08)
}

.line-scale>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes line-scale-party {

    0%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

@keyframes line-scale-party {

    0%,
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
}

.line-scale-party>div:nth-child(1) {
    -webkit-animation-delay: .55s;
    animation-delay: .55s;
    -webkit-animation-duration: 1.23s;
    animation-duration: 1.23s
}

.line-scale-party>div:nth-child(2) {
    -webkit-animation-delay: .55s;
    animation-delay: .55s;
    -webkit-animation-duration: .55s;
    animation-duration: .55s
}

.line-scale-party>div:nth-child(3) {
    -webkit-animation-delay: 90ms;
    animation-delay: 90ms;
    -webkit-animation-duration: .39s;
    animation-duration: .39s
}

.line-scale-party>div:nth-child(4) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-duration: .59s;
    animation-duration: .59s
}

.line-scale-party>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: line-scale-party;
    animation-name: line-scale-party;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-delay: 0;
    animation-delay: 0
}

@-webkit-keyframes line-scale-pulse-out {

    0%,
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    50% {
        -webkit-transform: scaley(.4);
        transform: scaley(.4)
    }
}

@keyframes line-scale-pulse-out {

    0%,
    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    50% {
        -webkit-transform: scaley(.4);
        transform: scaley(.4)
    }
}

.line-scale-pulse-out>div {
    -webkit-animation: line-scale-pulse-out .9s -.6s infinite cubic-bezier(.85, .25, .37, .85);
    animation: line-scale-pulse-out .9s -.6s infinite cubic-bezier(.85, .25, .37, .85)
}

.line-scale-pulse-out>div:nth-child(2),
.line-scale-pulse-out>div:nth-child(4) {
    -webkit-animation-delay: -.4s !important;
    animation-delay: -.4s !important
}

.line-scale-pulse-out>div:nth-child(1),
.line-scale-pulse-out>div:nth-child(5) {
    -webkit-animation-delay: -.2s !important;
    animation-delay: -.2s !important
}

@-webkit-keyframes line-scale-pulse-out-rapid {

    0%,
    90% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    80% {
        -webkit-transform: scaley(.3);
        transform: scaley(.3)
    }
}

@keyframes line-scale-pulse-out-rapid {

    0%,
    90% {
        -webkit-transform: scaley(1);
        transform: scaley(1)
    }

    80% {
        -webkit-transform: scaley(.3);
        transform: scaley(.3)
    }
}

.line-scale-pulse-out-rapid>div {
    vertical-align: middle;
    -webkit-animation: line-scale-pulse-out-rapid .9s -.5s infinite cubic-bezier(.11, .49, .38, .78);
    animation: line-scale-pulse-out-rapid .9s -.5s infinite cubic-bezier(.11, .49, .38, .78)
}

.line-scale-pulse-out-rapid>div:nth-child(2),
.line-scale-pulse-out-rapid>div:nth-child(4) {
    -webkit-animation-delay: -.25s !important;
    animation-delay: -.25s !important
}

.line-scale-pulse-out-rapid>div:nth-child(1),
.line-scale-pulse-out-rapid>div:nth-child(5) {
    -webkit-animation-delay: 0s !important;
    animation-delay: 0s !important
}

@-webkit-keyframes line-spin-fade-loader {
    50% {
        opacity: .3
    }

    100% {
        opacity: 1
    }
}

@keyframes line-spin-fade-loader {
    50% {
        opacity: .3
    }

    100% {
        opacity: 1
    }
}

.line-spin-fade-loader {
    position: relative;
    top: -10px;
    left: -4px
}

.line-spin-fade-loader>div:nth-child(1) {
    top: 20px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s -.84s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.84s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(2) {
    top: 13.64px;
    left: 13.64px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -.72s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.72s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(3) {
    top: 0;
    left: 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -.6s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.6s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(4) {
    top: -13.64px;
    left: 13.64px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -.48s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.48s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(5) {
    top: -20px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s -.36s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.36s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(6) {
    top: -13.64px;
    left: -13.64px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -.24s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.24s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(7) {
    top: 0;
    left: -20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -.12s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -.12s infinite ease-in-out
}

.line-spin-fade-loader>div:nth-child(8) {
    top: 13.64px;
    left: -13.64px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out
}

.line-spin-fade-loader>div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 5px;
    height: 15px
}

@-webkit-keyframes triangle-skew-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

@keyframes triangle-skew-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

.triangle-skew-spin>div {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    -webkit-animation: triangle-skew-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: triangle-skew-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite
}

@-webkit-keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

@keyframes square-spin {
    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0)
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg)
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0)
    }
}

.square-spin>div {
    width: 50px;
    height: 50px;
    background: #fff;
    -webkit-animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite;
    animation: square-spin 3s 0s cubic-bezier(.09, .57, .49, .9) infinite
}

.pacman>div:first-of-type,
.pacman>div:nth-child(2) {
    width: 0;
    height: 0;
    border-right: 25px solid transparent;
    border-top: 25px solid #fff;
    border-left: 25px solid #fff;
    border-bottom: 25px solid #fff;
    border-radius: 25px;
    position: relative;
    left: -30px
}

@-webkit-keyframes rotate_pacman_half_up {

    0%,
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate_pacman_half_up {

    0%,
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg)
    }

    50% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotate_pacman_half_down {

    0%,
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    50% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@keyframes rotate_pacman_half_down {

    0%,
    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    50% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
}

@-webkit-keyframes pacman-balls {
    75% {
        opacity: .7
    }

    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px)
    }
}

@keyframes pacman-balls {
    75% {
        opacity: .7
    }

    100% {
        -webkit-transform: translate(-100px, -6.25px);
        transform: translate(-100px, -6.25px)
    }
}

.pacman {
    position: relative
}

.pacman>div:nth-child(3) {
    -webkit-animation: pacman-balls 1s -.66s infinite linear;
    animation: pacman-balls 1s -.66s infinite linear
}

.pacman>div:nth-child(4) {
    -webkit-animation: pacman-balls 1s -.33s infinite linear;
    animation: pacman-balls 1s -.33s infinite linear
}

.pacman>div:nth-child(5) {
    -webkit-animation: pacman-balls 1s 0s infinite linear;
    animation: pacman-balls 1s 0s infinite linear
}

.pacman>div:first-of-type {
    -webkit-animation: rotate_pacman_half_up .5s 0s infinite;
    animation: rotate_pacman_half_up .5s 0s infinite
}

.pacman>div:nth-child(2) {
    -webkit-animation: rotate_pacman_half_down .5s 0s infinite;
    animation: rotate_pacman_half_down .5s 0s infinite;
    margin-top: -50px
}

.pacman>div:nth-child(3),
.pacman>div:nth-child(4),
.pacman>div:nth-child(5),
.pacman>div:nth-child(6) {
    background-color: #fff;
    border-radius: 100%;
    margin: 2px;
    width: 10px;
    height: 10px;
    position: absolute;
    -webkit-transform: translate(0, -6.25px);
    transform: translate(0, -6.25px);
    top: 25px;
    left: 70px
}

@-webkit-keyframes cube-transition {
    25% {
        -webkit-transform: translateX(50px) scale(.5) rotate(-90deg);
        transform: translateX(50px) scale(.5) rotate(-90deg)
    }

    50% {
        -webkit-transform: translate(50px, 50px) rotate(-180deg);
        transform: translate(50px, 50px) rotate(-180deg)
    }

    75% {
        -webkit-transform: translateY(50px) scale(.5) rotate(-270deg);
        transform: translateY(50px) scale(.5) rotate(-270deg)
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}

@keyframes cube-transition {
    25% {
        -webkit-transform: translateX(50px) scale(.5) rotate(-90deg);
        transform: translateX(50px) scale(.5) rotate(-90deg)
    }

    50% {
        -webkit-transform: translate(50px, 50px) rotate(-180deg);
        transform: translate(50px, 50px) rotate(-180deg)
    }

    75% {
        -webkit-transform: translateY(50px) scale(.5) rotate(-270deg);
        transform: translateY(50px) scale(.5) rotate(-270deg)
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg)
    }
}

.cube-transition {
    position: relative;
    -webkit-transform: translate(-25px, -25px);
    transform: translate(-25px, -25px)
}

.cube-transition>div {
    width: 10px;
    height: 10px;
    position: absolute;
    top: -5px;
    left: -5px;
    background-color: #fff;
    -webkit-animation: cube-transition 1.6s 0s infinite ease-in-out;
    animation: cube-transition 1.6s 0s infinite ease-in-out
}

.cube-transition>div:last-child {
    -webkit-animation-delay: -.8s;
    animation-delay: -.8s
}

@-webkit-keyframes spin-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.semi-circle-spin {
    position: relative;
    width: 35px;
    height: 35px;
    overflow: hidden
}

.semi-circle-spin>div {
    position: absolute;
    border-width: 0;
    border-radius: 100%;
    -webkit-animation: spin-rotate .6s 0s infinite linear;
    animation: spin-rotate .6s 0s infinite linear;
    background-image: -webkit-linear-gradient(transparent 0, transparent 70%, #fff 30%, #fff 100%);
    background-image: linear-gradient(transparent 0, transparent 70%, #fff 30%, #fff 100%);
    width: 100%;
    height: 100%
}