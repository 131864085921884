.simple-banner {
    width: 100%;
    text-align: center;
    position: relative;
    display: block;
  }
  
  .simple-banner .simple-banner-text {
    font-weight: 700;
    padding: 10px 20px;
  }
  
  .simple-banner .simple-banner-button {
    border: none;
    background-color: transparent;
    padding: 0 5px;
    margin: 0 10px;
    text-align: center;
    text-decoration: none;
    position: absolute;
    transform: translate(0%, -50%);
    right: 0;
    top: 50%;
    font-size: 16px;
    cursor: pointer;
  }