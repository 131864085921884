.elementor-4589 .elementor-element.elementor-element-72a710cc:not(.elementor-motion-effects-element-type-background),
.elementor-4589 .elementor-element.elementor-element-72a710cc>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: #0633F8;
}

.elementor-4589 .elementor-element.elementor-element-72a710cc {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-4589 .elementor-element.elementor-element-72a710cc>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-4589 .elementor-element.elementor-element-13761626>.elementor-element-populated {
    padding: 0px 0px 0px 0px;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-banner {
    height: 800px;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption h1 {
    font-family: "Barlow", Sans-serif;
    font-weight: 500;
    color: #FFFFFF;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption h1 span {
    font-family: "Barlow", Sans-serif;
    font-weight: 500;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption h5 {
    color: #FFFFFF;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption p {
    color: #FFFFFF;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 span.sahub-free-trail-txt {
    font-family: "Caveat", Sans-serif;
    font-size: 23px;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-one {
    padding: 14px 25px 14px 25px;
    background-color: #0633F8;
    box-shadow: 0px 0px 31px -7px rgba(6, 51, 248, 0.69);
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-one i {
    color: #0633F8;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-one:hover {
    color: #0633F8;
    background-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #0633F8;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-one:hover i {
    color: #FFFFFF;
    background: #0633F8;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-two {
    color: #C22B2B;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-btn-wrap .sahub-btn.btn-two:hover {
    color: #23A455;
}

.elementor-4589 .elementor-element.elementor-element-7380fba2>.elementor-widget-container {
    padding: 0px 0px 0px 0px;
    background-color: #FFFFFF;
}

@media(max-width:767px) {
    .elementor-4589 .elementor-element.elementor-element-72a710cc {
        padding: 0px 0px 0px 0px;
    }

    .elementor-4589 .elementor-element.elementor-element-13761626>.elementor-element-populated {
        padding: 0px 0px 0px 0px;
    }

    .elementor-4589 .elementor-element.elementor-element-7380fba2 .sahub-banner {
        height: 500px;
    }

    .elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption h1 {
        font-size: 30px;
    }

    .elementor-4589 .elementor-element.elementor-element-7380fba2 .banner-caption p {
        font-size: 18px;
    }
}