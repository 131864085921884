/**
 * VictorThemes Styling
*/

@media screen and (max-width:1699px) {
    /* Sahub E Book Page CSS */
    .sahub-ebook {
      padding: 60px;
    }
    .full-page-info h2 {
      font-size: 32px;
      line-height: 38px;
    }
    .technology-item {
      padding-right: 0;
    }
    /* Sahub It Page CSS */
    .it-info {
      padding-left: 100px;
      padding-right: 100px;
    }
    .sahub-banner .sahub-image {
      padding-left: 35px;
    }
    .sahub-awards .owl-carousel .owl-nav button.owl-prev {
      left: -20px;
    }
    .sahub-awards .owl-carousel .owl-nav button.owl-next {
      right: -20px;
    }
  
  }
  @media screen and (max-width:1620px) {
    .services-wrap .tab-content>.tab-pane .sahub-image img {
      max-width: 100%;
    }
    .services-wrap.tab-style-one {
      padding-bottom: 40px;
    }
    .sahub-mid-wrap.sahub-post-single {
      padding-top: 180px;
    }
  }
  
  @media screen and (max-width:1459px) {
    /* Sahub E Book Page CSS */
    .full-page-inner .technology-item h3 {
      font-size: 20px;
    }
    /* Sahub It Page CSS */
    .it-info {
      padding-left: 80px;
      padding-right: 80px;
    }
    .single-post .sahub-primary {
      padding-right: 15px;
    }
  }
  
  @media screen and (max-width:1449px) {
    /* Sahub Normal CSS */
    .sahub-banner .sahub-image {
      padding-left: 0;
    }
    .sahub-banner .sahub-image img {
      max-width: 100%;
    }
    .news-bottom-wrap.single-bottom-wrap .news-bottom-metas {
      width: 100%;
      float: none;
    }
    .news-bottom-wrap.single-bottom-wrap .news-bottom-metas .news-share {
      margin-bottom: 10px;
    }
  }
  
  @media screen and (max-width:1399px) {
    /* Sahub Home V1 Page CSS */
    .owl-carousel.nav-style-two .owl-nav button.owl-prev {
      left: -40px;
    }
    .owl-carousel.nav-style-two .owl-nav button.owl-next {
      right: -40px;
    }
    /* Sahub Aboutus Page CSS */
    .sahub-about .sahub-image img {
      max-width: 100%;
    }
    .no-max-width img,
    .no-max-width {
      max-width: 100% !important;
    }
    /* Sahub E Book Page CSS */
    .full-page-wrap .sahub-header {
      padding: 32px 30px;
    }
    .sahub-ebook {
      padding: 50px 30px;
    }
    .full-page-inner {
      padding-left: 30px;
      padding-right: 30px;
    }
    /* Sahub Request A Demo Page CSS */
    .watch-image {
      margin-left: 0;
    }
    .sahub-video-wrap .watch-image img,
    .watch-image img {
      max-width: 100%;
    }
    .watch-image .video-btn {
      width: 63px;
      height: 63px;
      font-size: 18px;
    }
    .customers-style-three .owl-carousel .owl-nav button.owl-prev {
      left: -25px;
    }
    .customers-style-three .owl-carousel .owl-nav button.owl-next {
      right: -25px;
    }
    /* Sahub Workshops Page CSS */
    .started-style-two .sahub-image img {
      max-width: 100%;
    }
  }
  
  @media screen and (max-width:1280px) {
    /* Sahub Home V1 Page CSS */
    .owl-carousel.nav-style-two .owl-nav button.owl-prev {
      left: -10px;
    }
    .owl-carousel.nav-style-two .owl-nav button.owl-next {
      right: -10px;
    }
    /* Sahub Careers Page CSS */
    .process-item {
      padding-right: 125px;
    }
    .news-bottom-metas .share-links {
      left: -25px;
    }
  }
  
  @media screen and (max-width:1280px) and (min-width:1200px) { 
    .sahub-navigation ul li a {
      padding-right:21px;
    }
  }
  
  @media screen and (min-width:1270px) {
    /* Sahub Normal CSS */
    .container {
      padding: 0;
    }
    .sahub-support .row {
      margin: 0 -36px;
    }
    .sahub-support [class*="col-"] {
      padding: 0 36px;
    }
    /* Sahub Security Page CSS */
    .sahub-security .row {
      margin: 0 -38px;
    }
    .sahub-security [class*="col-"] {
      padding: 0 38px;
    }
  }
  
  @media screen and (max-width:1269px) {
    /* Sahub Security Page CSS */
    .security-item {
      margin-bottom: 30px;
    }
    /* Sahub Solutions Page CSS */
    .solution-filter ul li {
      padding-left: 23px;
      padding-right: 23px;
    }
  
  }
  
  @media screen and (min-width:1200px) {
    /* Sahub Normal CSS */
    .container {
      max-width: 1170px;
    }
    .has-full-page {
      padding-bottom: 0 !important;
    }
  }
  @media screen and (min-width:1199px) {
    /* Mega Menu*/
    .mega-menu .dropdown-nav {
      min-width: 730px;
      text-align: center;
    }
    .mega-menu .dropdown-nav li a {
      display: block;
      padding: 35px 30px 30px;
      font-weight: 600;
      font-size: 20px;
      text-transform: capitalize;
    }
    .mega-menu ul.dropdown-nav li:last-child a {
      padding-right: 30px;
    }
    .mega-menu ul.dropdown-nav li:hover > a {
      padding-left: 30px;
    }
    .sahub-navigation > ul > li.mega-menu .dropdown-nav,
    .sahub-navigation > ul > li.mega-menu:nth-last-child(-n+3).menu-item .dropdown-nav {
      left: 50%;
      transform: translateX(-50%);
    }
    .mega-menu .dropdown-nav li.menu-spacer {
      width: 50%;
      float: left;
    }
    .mega-menu .dropdown-nav li {
      display: inline-block;
      width: 100%;
    }
    .mega-menu .dropdown-nav li.right-border {
      border-right: 1px solid #eeeeee;
    }
    span.sahub-image-wraper {
      display: inline-block;
      text-align: left;
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
    }
    span.sahub-image-wraper span.sahub-image {
      float: left;
      padding: 0 20px 0 0;
      display: inline-block;
    }
    span.sahub-image-wraper span.menu-text {
      display: inline-block;
      width: calc(100% - 85px);
    }
  
  }
  
  @media screen and (max-width:1199px) {
    /* Sahub Normal CSS */
    .sahub-header {
      padding: 0 25px;
    }
    .sahub-navigation > ul > li > a {
      padding: 40px 26px 40px 0;
    }
    .sahub-navigation > ul > li:last-child > a {
      padding-right: 10px;
    }
    .banner-caption {
      padding: 0;
    }
    .banner-caption h1 {
      font-size: 42px;
      line-height: 47px;
    }
    .banner-caption p {
      font-size: 18px;
      line-height: 30px;
    }
    .banner-caption form {
      padding-top: 20px;
    }
    .banner-caption input[type="email"] {
      width: 280px;
    }
    .blog-item {
      padding: 35px 25px;
    }
    .blog-item h3 {
      font-size: 18px;
    }
    .sahub-sticky-footer .main-wrap-inner {
      margin-bottom: 0 !important;
    }
    .sahub-sticky-footer .sahub-footer {
      position: static;
    }
    .page-title-wrap h1 {
      font-size: 42px;
      line-height: 50px;
    }
    .owl-carousel .owl-nav button.owl-prev {
      left: -20px;
    }
    .owl-carousel .owl-nav button.owl-next {
      right: -20px;
    }
    .masonry-filters {
      overflow-x: scroll;
      white-space: nowrap;
      padding-bottom: 0;
      margin-bottom: 30px;
      font-size: 16px;
    }
    .masonry-filters ul li {
      padding: 0 15px;
    }
    /* Sahub Home V1 Page CSS */
    .tool-item {
      padding: 25px 20px 20px;
    }
    .tool-item h4 {
      font-size: 18px;
    }
    .compare-item,
    .compare-item.compare-spacer {
      padding: 0;
    }
    .skills-wrap {
      max-width: 620px;
    }
    .skill-item h2 {
      font-size: 35px;
    }
    /* Sahub Home V2 Page CSS */
    .sahub-banner.banner-style-two {
      height: 850px;
    }
    .banner-style-two .banner-caption {
      padding: 50px 0 0;
    }
    .features-style-two .feature-item h3 {
      font-size: 20px;
    }
    .footer-logos-wrap {
      max-width: 840px;
    }
    .widget-demo {
      padding-left: 25px;
      padding-right: 25px;
      margin-left: 0;
    }
    .award-item {
      text-align: center;
    }
    .award-item .sahub-image {
      float: none;
      padding: 0;
    }
    .award-info {
      padding: 20px 0 0;
      border-left: none;
    }
    .integration-item {
      padding-bottom: 20px;
    }
    .integration-item .sahub-icon {
      max-width: 140px;
    }
    .services-wrap.tab-style-two .container {
      max-width: 90%;
    }
    .services-wrap.tab-style-two {
      padding-bottom: 25px;
    }
    .services-wrap.tab-style-two ul.nav-tabs {
      margin-bottom: 0;
    }
    .services-wrap .tab-content {
      padding-bottom: 30px;
      padding-top: 30px;
    }
    /* Sahub 404 Page CSS */
    .sahub-error-wrap {
      min-height: 10px;
      padding: 150px 0;
    }
    .error-wrap h1 {
      font-size: 200px;
      line-height: 190px;
    }
    /* Sahub Aboutus Page CSS */
    .sahub-about .sahub-image {
      padding-left: 0;
    }
    /* Sahub Advantages Page CSS */
    .advantage-item .sahub-image.image-spacer-two {
      padding-left: 0;
    }
    /* Sahub All Apps Page CSS */
    .app-image .sahub-image {
      margin: 0;
    }
    .apps-gray-background .app-image .sahub-image {
      padding: 0;
    }
    .app-info h2 {
      font-size: 32px;
      line-height: 38px;
    }
    /* Sahub Alternatives Page CSS */
    .alternative-item br {
      display: none;
    }
    /* Sahub Brand Kit Page CSS */
    .brand-kits-wrap {
      padding-top: 50px;
    }
    .brand-color-item {
      width: 125px;
      margin: 0 25px 25px 0;
    }
    .brand-kits-wrap .sahub-btn-wrap {
      padding-top: 25px;
    }
    /* Sahub Careers Page CSS */
    .sahub-effort .sahub-image img {
      width: 430px;
    }
    .benefit-item h3 {
      font-size: 20px;
    }
    .process-item {
      padding-right: 70px;
    }
    .what-we-wrap .row {
      margin: 0 -15px;
    }
    .what-we-wrap [class*="col-"] {
      padding: 0 15px;
    }
    .what-we-item h3 {
      font-size: 20px;
    }
    /* Sahub Careers Single Page CSS */
    .sahub-sidebar {
      padding: 0;
    }
    /* Sahub Change Log Page CSS */
    .log-sidebar {
      -ms-flex: 0 0 32%;
      flex: 0 0 32%;
      max-width: 32%;
    }
    .log-details {
      padding-left: 25px;
      -ms-flex: 0 0 68%;
      flex: 0 0 68%;
      max-width: 68%;
    }
    /* Sahub Contactus Page CSS */
    .contact-form {
      padding-right: 0;
    }
    .contact-wrap {
      padding-left: 0;
    }
    .office-item {
      max-width: 373px;
      margin: 0 auto 30px;
    }
    .client-label {
      padding-bottom: 30px;
    }
    .client-wrap {
      padding-left: 0;
    }
    .office-info {
      padding: 20px;
    }
    .office-info h3 {
      font-size: 20px;
    }
    .office-info ul {
      font-size: 15px;
    }
    .office-info .sahub-icon {
      width: 145px;
      height: 70px;
      min-height: 10px;
      margin-bottom: 15px;
      background-size: 285px auto;
    }
    .office-info .sahub-icon.japan {
      width: 100px;
      height: 70px;
      background-size: 195px auto;
    }
    .office-info .sahub-icon.australia {
      width: 135px;
      height: 67px;
      background-size: 270px auto;
    }
    .office-info .sahub-icon.united-states {
      width: 85px;
      height: 70px;
      background-size: 170px auto;
    }
    .office-info .sahub-icon.india {
      width: 130px;
      height: 75px;
      background-size: 260px auto;
    }
    .office-info .sahub-icon.rome {
      width: 102px;
      height: 70px;
      background-size: 209px auto;
    }
    .office-place {
      padding-bottom: 5px;
    }
    .sahub-client.client-style-two {
      text-align: center;
    }
    /* Sahub Core Values Page CSS */
    .core-value-info,
    .one-fourth .core-value-info,
    .one-fourth-two .core-value-info,
    .one-half-two .core-value-info {
      padding: 30px;
    }
    .core-value-count {
      font-size: 60px;
      line-height: 60px;
    }
    .core-value-info h2 {
      font-size: 26px;
      line-height: 34px;
    }
    /* Sahub Customers Page CSS */
    .trusted-item-wrap > .row {
      margin: 0 -15px;
    }
    .trusted-item-wrap > .row > [class*="col-"] {
      padding: 0 15px;
    }
    /* Sahub Customers Single Page CSS */
    .features-style-two .feature-item {
      padding: 30px;
    }
    .customer-info-item,
    .customer-info-item.customer-spacer-one {
      padding: 0;
    }
    .quote-info {
      padding: 40px 40px 30px;
    }
    /* Sahub Designing Page CSS */
    .technology-image img {
      width: 600px;
    }
    .technology-item {
      padding-right: 0;
    }
    .promo-info,
    [class*="order-"] .promo-info {
      padding: 0;
    }
    .promo-title br {
      display: none;
    }
    .features-style-four .feature-item {
      padding: 30px 25px 20px;
    }
    .features-style-four .feature-item h3 {
      font-size: 20px;
    }
    /* Sahub E Book Page CSS */
    .has-full-page {
      height: auto;
      min-height: 100%;
      position: relative;
    }
    .has-full-page body,
    .sahub-full-page > .row,
    .full-page-wrap,
    .sahub-ebook {
      height: auto;
    }
    .sahub-full-page {
      min-height: 10px;
    }
    .sahub-full-page .full-page-primary,
    .sahub-full-page .full-page-secondary {
      min-height: 10px;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .full-page-inner {
      padding: 0 50px 25px;
    }
    .sahub-ebook {
      padding: 50px;
    }
    .full-page-wrap .sahub-copyright {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
    /* Sahub Faq Page CSS */
    .questions-labels {
      padding-left: 30px;
    }
    .faq-item {
      padding: 30px 25px;
      margin-bottom: 10px;
    }
    /* Sahub It Page CSS */
    .it-info {
      padding: 50px;
    }
    .it-info h2 {
      font-size: 32px;
      line-height: 40px;
    }
    .sahub-background {
      min-height: 500px;
    }
    .it-title br {
      display: none;
    }
    /* Sahub No Openings Page CSS */
    .vacant-wrap h2 {
      font-size: 40px;
    }
    /* Sahub Partner Page CSS */
    .sahub-profit .section-title-wrap {
      max-width: 610px;
      padding: 0 0 30px;
      text-align: center;
    }
    .sahub-investors .row {
      margin: 0 -15px;
    }
    .sahub-investors [class*="col-"] {
      padding: 0 15px;
    }
    .investor-item {
      margin-bottom: 30px;
    }
    /* Sahub Pricings 01 Page CSS */
    .price-inner {
      padding: 30px 25px;
    }
    .price-inner h2 {
      font-size: 40px;
      line-height: 45px;
    }
    .price-info {
      min-height: 500px;
      padding: 30px 25px 20px;
    }
    .plans-wrap .table td,
    .plans-wrap .table thead th {
      padding-left: 20px;
      padding-right: 20px;
    }
    .question-item {
      padding-right: 0;
    }
    /* Sahub Pricings 02 Page CSS */
    .price-info-wrap {
      padding: 30px 30px 20px;
    }
    .price-item-wrap {
      padding: 30px;
    }
    /* Sahub Pricings 03 Page CSS */
    .pricings-style-three .price-inner,
    .pricings-style-three .price-info {
      padding: 30px 25px;
    }
    .pricings-style-three .price-info p {
      font-size: 16px;
    }
    /* Sahub Pricings 04 Page CSS */
    .plan-includes .check-list {
      font-size: 16px;
    }
    /* Sahub Product Page CSS */
    .product-info-wrap {
      padding: 0 0 80px;
    }
    .product-image {
      padding: 0 0 30px;
    }
    .product-info {
      padding-right: 0;
    }
    .product-item {
      padding-right: 0;
    }
    /* Sahub Product Single Page CSS */
    .doc-image img {
      max-width: 100%;
    }
    .doc-wrap {
      padding: 0 0 0 30px;
    }
    /* Sahub Referal Page CSS */
    .affiliate-info h2 {
      font-size: 34px;
      line-height: 42px;
    }
    /* Sahub Request A Demo Page CSS */
    .watch-form {
      padding-right: 30px;
    }
    /* Sahub Roadmap Page CSS */
    .roadmap-info {
      padding-left: 0;
    }
    .roadmap-item .check-list,
    .roadmap-updates,
    .number-list {
      font-size: 17px;
    }
    .roadmap-item h2 {
      font-size: 30px;
    }
    .update-title {
      padding-top: 4px;
    }
    /* Sahub Security Page CSS */
    .security-item {
      padding: 40px 30px;
    }
    .security-info {
      padding-left: 20px;
    }
    /* Sahub Services Page CSS */
    .expert-info {
      padding-right: 0;
    }
    .tools-style-two .tool-item {
      padding: 35px 25px;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-prev {
      left: -180px;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-next {
      right: -180px;
    }
    .business-info {
      padding-left: 0;
    }
    /* Sahub Solutions Page CSS */
    .sahub-banner.banner-style-three {
      height: 650px;
    }
    .banner-style-three .banner-caption h1 {
      font-size: 44px;
      line-height: 60px;
    }
    .solution-filter ul {
      overflow-x: scroll;
      white-space: nowrap;
      font-size: 18px;
    }
    .solution-filter ul li {
      padding: 15px 19px;
    }
    .solution-item h2 {
      font-size: 32px;
    }
    .solutions-wrap {
      padding-right: 0;
    }
    .solutions-inner {
      padding: 0 0 20px;
    }
    .solutions-inner-info {
      padding-left: 20px;
    }
    .solutions-secondary {
      padding-left: 0;
    }
    .map-image img {
      max-width: 100%;
    }
    .customers-style-three .customer-item {
      padding-left: 25px;
      padding-right: 25px;
    }
    /* Sahub Success Stories Page CSS */
    .sahub-masonry[data-items="3"] {
      margin: 0 -15px;
    }
    .sahub-masonry[data-items="3"] .masonry-item {
      padding: 0 15px 30px;
    }
    /* Sahub Support Page CSS */
    .contact-style-two .contact-form {
      padding-right: 30px;
    }
    /* Sahub Team Page CSS */
    .mate-info h3 {
      font-size: 20px;
    }
    /* Sahub Workshops Page CSS */
    .started-wrap {
      padding: 0;
    }
    .started-style-two .sahub-image {
      padding-left: 0;
    }
    .testimonials-style-six .sahub-image {
      width: 28%;
    }
    .price-item.style-four.advance-pricing {
      padding-top: 56px
    }
    .price-item.style-four h4.advanced-title {
      top:0;
    }
      
  }
  
  @media screen and (max-width:1199px) and (min-width:992px) {
    /* Sahub Home V2 Page CSS */
    .location-item {
      top: 100px;
      left: 80px;
    }
    .location-item:nth-child(2n) {
      top: 135px;
      left: 110px;
    }
    .location-item:nth-child(3n) {
      top: 110px;
      left: 300px;
    }
    .location-item:nth-child(4n) {
      top: 80px;
      left: 380px;
    }
    .location-item:nth-child(5n) {
      top: 220px;
      left: 165px;
    }
    .location-item:nth-child(6n) {
      top: auto;
      left: auto;
      right: 60px;
      bottom: 60px;
    }
  }
  
  @media screen and (min-width:992px) {
    .sahub-it-sector .container {
      max-width: none;
    }
    /* Sahub Pricings 01 Page CSS */
    .pricings-wrap .row {
      margin: 0;
    }
    .pricings-wrap [class*="col-"] {
      padding: 0;
    }
    /* Sahub Pricings 02 Page CSS */
    .pricings-style-two .row {
      margin: 0 -12px;
    }
    .pricings-style-two [class*="col-"] {
      padding: 0 12px;
    }
    /* Sahub Referal Page CSS */
    .sahub-affiliates .row {
      margin: 0;
    }
    .sahub-affiliates [class*="col-"] {
      padding: 0;
    }
  }
  
  @media screen and (max-width:991px) {
    /* Sahub Normal CSS */
    .table thead th {
      padding: 16px 25px;
      font-size: 18px;
    }
    .table td {
      padding: 16px 25px;
      font-size: 16px;
    }
    .sahub-banner .sahub-image img {
      width: 500px;
    }
    .banner-caption {
      padding-bottom: 35px;
    }
    .sahub-banner.banner-style-two.banner-style-four:before {
      background: rgba(255, 255, 255, 0.63);
    }
    .banner-two .sahub-banner.banner-style-two.banner-style-four:before {
      background: transparent;
    }
    .banner-title {
      padding-bottom: 10px;
    }
    .banner-caption form {
      padding-top: 10px;
    }
    .feature-item .textright {
      text-align: left;
    }
    .feature-item .sahub-image img {
      width: 450px;
    }
    .feature-info {
      padding: 35px 0 0;
    }
    .feature-info .section-title-wrap {
      max-width: none;
    }
    .feature-info br {
      display: none;
    }
    .section-title-wrap h2 {
      font-size: 32px;
      line-height: 40px;
    }
    .skills-wrap {
      max-width: none;
    }
    .skills-wrap:before,
    .skills-wrap:after {
      display: none;
    }
    .compare-label {
      padding: 20px 0;
    }
    .compare-label:before,
    .compare-label:after {
      display: none;
    }
    .compare-item .sahub-icon {
      margin-bottom: 10px;
    }
    .widget-spacer-one,
    .widget-spacer-two,
    .widget-spacer-three {
      padding-left: 0;
    }
    .footer-widget-title {
      padding-bottom: 15px;
    }
    .nav-tabs .nav-link {
      font-size: 18px;
    }
    .sahub-page-title {
      padding: 100px 0;
    }
    .page-title-wrap h1 {
      font-size: 40px;
      line-height: 48px;
    }
    .page-title-wrap p {
      font-size: 18px;
    }
    .banner-style-four.banner-style-two .banner-caption p {
      font-size: 16px;
      margin: 20px 0;
    }
      .sahub-banner.banner-style-two:before{display:none;}
    .services-wrap.tab-style-one {
      padding-bottom: 0;
    }
    .services-wrap ul.nav-tabs {
      margin-bottom: 20px;
    }
    .sahub-it-sector.sahub-freelance .sahub-background:after,
    .sahub-it-sector.sahub-freelance .order-lg-2 .sahub-background:after {
      border-radius: 0;
    }
    .sahub-it-sector.sahub-freelance .sahub-background {
      background-size: contain;
    }
    .sahub-it-sector [class*="col-"] {
      width: 50%;
    }
    .workflow-item {
      width: 50%;
      padding-bottom: 10px;
    }
    .workflow-item:nth-child(2n) {
      top: 0;
    }
    .workflow-item:nth-child(odd):after {
      content: none;
    }
    .workflow-item:nth-child(2n):after {
      content: none;
    }
    .workflow-item h3.workflow-number {
      font-size: 40px;
      position: relative;
      bottom: 7px;
      line-height: 1;
    }
    .workflow-wrap {
      padding-bottom: 0;
    }
    /* Sahub Home V2 Page CSS */
    .sahub-banner.banner-style-two {
      height: 700px;
      padding: 0 0 50px;
    }
    .sahub-banner .header-btns {
      padding-top: 10px;
    }
    .banner-style-two .banner-caption {
      padding-top: 0;
    }
    .banner-style-two.banner-style-four .banner-caption {
      max-width: 100%;
    }
    .features-style-two .feature-item {
      padding: 30px;
    }
    .sahub-testimonials.testimonials-style-two {
      text-align: center;
    }
    .testimonials-style-two .owl-carousel .owl-nav button.owl-prev {
      left: -30px;
    }
    .testimonials-style-two .owl-carousel .owl-nav button.owl-next {
      right: -30px;
    }
    .testimonials-style-two .sahub-image {
      float: none;
      padding: 0;
    }
    .testimonial-info {
      padding: 0;
    }
    .testimonial-info:before,
    .testimonial-info p:before,
    .testimonial-info p:after {
      display: none;
    }
    .sahub-world-wide {
      text-align: center;
    }
    .world-wrap {
      max-width: 460px;
      padding-top: 30px;
      margin: 0 auto;
    }
    .integration-spacer {
      padding-left: 0;
    }
    .support-item {
      margin-bottom: 30px;
    }
    .sahub-footer.footer-style-two .footer-wrap {
      padding-top: 60px;
    }
    .footer-logos-wrap {
      max-width: none;
    }
    .widget-demo {
      margin: 0;
    }
    .widget-demo .footer-widget-title {
      padding-bottom: 25px;
    }
    .footer-style-two .sahub-copyright .textright {
      text-align: center;
    }
    .footer-style-two .sahub-copyright {
      padding-bottom: 30px;
      text-align: center;
    }
    .copyright-links {
      padding-bottom: 10px;
    }
    .copyright-links .sahub-icon {
      margin: 0 5px 10px;
    }
    /* Sahub 404 Page CSS */
    .sahub-error-wrap {
      padding: 100px 0 60px;
    }
    .error-wrap h1 {
      font-size: 160px;
      line-height: 150px;
    }
    .error-wrap h2 {
      font-size: 40px;
      line-height: 48px;
    }
    .team-total-social {
      width: 27%;
    }
    .mate-name-place {
      width: 75%;
    }
    /* Sahub Aboutus Page CSS */
    .about-info {
      padding: 40px 0 0;
    }
    .about-info h2 {
      font-size: 42px;
      line-height: 50px;
    }
    .sahub-about .sahub-image img {
      width: 400px;
    }
    /* Sahub Advantages Page CSS */
    .advantage-item .sahub-image {
      padding: 0 0 30px;
    }
    .advantage-spacer-one {
      padding-bottom: 80px;
    }
    .advantage-spacer-two {
      padding-bottom: 60px;
    }
    .advantage-item .sahub-image img {
      width: 450px;
    }
    /* Sahub All Apps Page CSS */
    .sahub-apps,
    .sahub-apps .textright {
      text-align: center;
    }
    .app-image .sahub-image,
    .apps-gray-background .app-image .sahub-image {
      padding-bottom: 30px;
    }
    .app-image .sahub-image img {
      width: 400px;
    }
    .app-info .sahub-btn {
      margin: 0 5px 10px;
    }
    .sahub-webapp .app-info {
      padding: 30px 0 0;
    }
    /* Sahub App Integration Page CSS */
    .app-integration-info {
      padding-left: 10px;
    }
    [class*="order-"] .app-integration-info {
      padding-right: 10px;
    }
    .integration-tools {
      padding: 0;
    }
    .integration-tool-item h3 {
      font-size: 18px;
    }
    .sahub-build {
      padding-top: 100px;
    }
    .sahub-build .sahub-image img {
      width: 450px;
    }
    .build-info .section-title-wrap {
      padding-top: 25px;
    }
    .app-integration-info h2 {
      font-size: 32px;
    }
    /* Sahub Brand Kit Page CSS */
    .page-title-wrap .sahub-btn-wrap {
      padding-top: 15px;
    }
    .sahub-brand-styles .section-title-wrap {
      padding-bottom: 10px;
    }
    .sahub-brand-types .row {
      margin: 0 -15px;
    }
    .sahub-brand-types [class*="col-"] {
      padding: 0 15px;
    }
    .brand-type-item .fa {
      top: 15px;
      right: 20px;
    }
    /* Sahub Careers Page CSS */
    .sahub-effort {
      padding: 80px 0;
      text-align: center;
    }
    .sahub-effort .textright {
      text-align: center;
    }
    .sahub-effort.effort-gray-background {
      padding: 80px 0;
    }
    .effort-info .section-title-wrap {
      padding: 40px 0 0;
      text-align: center;
    }
    .effort-info .section-title-wrap p {
      padding-bottom: 10px;
    }
    .process-item {
      max-width: 33.333333%;
      padding: 0 0 30px;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
    }
    .process-item:before,
    .process-item:after {
      display: none;
    }
    .process-info {
      max-width: none;
    }
    .what-we-item {
      margin-bottom: 30px;
    }
    .what-we-item h3 {
      font-size: 18px;
    }
    .what-we-image img {
      width: 600px;
    }
    .effort-info .sahub-btn-wrap {
      padding-top: 20px;
    }
    .what-we-image {
      padding-bottom: 50px;
    }
    /* Sahub Careers Single Page CSS */
    .sahub-sidebar {
      padding-bottom: 50px;
    }
    /* Sahub Change Log Page CSS */
    .mid-spacer-two {
      padding: 70px 0;
    }
    .log-sidebar {
      padding-bottom: 15px;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .log-details {
      padding: 0 15px;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .log-sidebar .nav-tabs .nav-item {
      margin-bottom: 15px;
    }
    .log-sidebar .nav-tabs .nav-link {
      border: 1px solid #dcdced;
    }
    .log-sidebar .nav-tabs {
      box-shadow: none;
      padding-top: 0;
    }
    /* Sahub Contactus Page CSS */
    .contact-wrap {
      padding-top: 30px;
    }
    .contact-wrap:before,
    .contact-wrap:after {
      display: table;
      content: "";
    }
    .contact-wrap:after {
      clear: both;
    }
    .contact-item {
      float: left;
      width: 50%;
      padding: 0 20px 40px 0;
    }
    .contact-form textarea,
    .contact-form textarea.form-control {
      height: 160px;
    }
    /* Sahub Core Values Page CSS */
    .sahub-core-values .masonry-item {
      width: 50%;
    }
    /* Sahub Customers Single Page CSS */
    .customer-info-item {
      padding-bottom: 20px;
    }
    .customer-info-title {
      padding-bottom: 10px;
    }
    .sahub-quotes {
      max-width: 380px;
    }
    .sahub-quotes .sahub-image {
      float: none;
    }
    .quote-info {
      max-width: 380px;
      border-top: none;
      border-left: 1px solid #dcdced;
    }
    /* Sahub Designing Page CSS */
    .technology-image img {
      width: 450px;
    }
    .technology-wrap {
      padding-top: 70px;
    }
    .sahub-promo .textright,
    .promo-info.promo-info-two {
      text-align: left;
    }
    .promo-info,
    [class*="order-"] .promo-info {
      padding-top: 30px;
    }
    .promo-info h2 {
      font-size: 30px;
    }
    .promo-info br {
      display: none;
    }
    .promo-item + .promo-item {
      padding-top: 50px;
    }
    /* Sahub Faq Page CSS */
    .questions-search input[type="text"] {
      width: 100%;
    }
    .questions-search h2 {
      font-size: 28px;
      line-height: 35px;
    }
    .questions-wrap {
      padding-top: 60px;
    }
    .questions-labels {
      padding: 40px 0 0;
    }
    .questions-labels .questions-title {
      padding-bottom: 20px;
    }
    /* Sahub It Page CSS */
    .it-info {
      padding: 65px 0 60px;
    }
    /* Sahub Integration Single Page CSS */
    .sahub-integration-single .promo-item + .promo-item {
      padding-top: 65px;
    }
    .sahub-integration-single .promo-item .sahub-image img {
      width: 600px;
    }
    .sahub-integration-single .promo-info,
    .sahub-integration-single [class*="order-"] .promo-info {
      padding: 20px 0 0;
    }
    /* Sahub Partner Page CSS */
    .profit-form-wrap {
      max-width: none;
    }
    /* Sahub No Openings Page CSS */
    .vacant-wrap h2 {
      font-size: 34px;
    }
    /* Sahub Pricings 01 Page CSS */
    .price-item {
      margin: 0 0 30px;
    }
    .plans-wrap .sahub-responsive-table {
      white-space: nowrap;
    }
    .plans-wrap .table thead tr th:first-child {
      font-size: 18px;
    }
    .plans-wrap .table td,
    .plans-wrap .table thead th {
      padding-top: 13px;
      padding-bottom: 13px;
    }
    .sahub-compare-plans .section-title-wrap {
      padding-bottom: 40px;
    }
    /* Sahub Pricings 02 Page CSS */
    .testimonial-item .sahub-image {
      padding: 0;
    }
    /* Sahub Pricings 03 Page CSS */
    .sahub-pricings .price-item.border-right-none {
      border-right-color: #dcdced;
    }
    .sahub-pricings .price-item.border-left-none {
      border-left-color: #dcdced;
    }
    /* Sahub Product Page CSS */
    .product-info-wrap {
      padding: 0 0 50px;
    }
    .product-image img {
      width: 550px;
    }
    /* Sahub Product Management Page CSS */
    .sahub-video {
      padding: 110px 0;
    }
    /* Sahub Product Single Page CSS */
    .pricings-spacer-two .price-meta {
      padding-top: 10px;
    }
    .pricings-spacer-two .price-item {
      margin-bottom: 15px;
    }
    .doc-wrap {
      padding: 40px 0 0;
    }
    .doc-item {
      padding-bottom: 20px;
    }
    /* Sahub Referal Page CSS */
    .affiliate-item {
      margin: 0 0 30px;
    }
    /* Sahub Request A Demo Page CSS */
    .sahub-watch .section-title-wrap {
      padding-bottom: 40px;
    }
    .sahub-watch .section-title-wrap h2 {
      font-size: 38px;
      line-height: 44px;
    }
    .watch-form {
      padding-right: 0;
    }
    .watch-image {
      margin-bottom: 40px;
    }
    .watch-inner-wrap {
      padding: 35px 30px;
    }
    .watch-title {
      text-align: left;
    }
    /* Sahub Services Page CSS */
    .expert-info,
    .business-info {
      padding-top: 40px;
    }
    .expert-info br,
    .business-info br {
      display: none;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-prev {
      left: -130px;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-next {
      right: -130px;
    }
    /* Sahub Signin Signup Page CSS */
    .login-wrap {
      padding: 70px 40px 60px;
    }
    /* Sahub Solutions Page CSS */
    .sahub-banner.banner-style-three {
      height: 500px;
    }
    .banner-style-three .banner-caption {
      max-width: none;
    }
    .banner-style-three .banner-caption h1 {
      font-size: 38px;
      line-height: 48px;
    }
    .sahub-main-banner .sticky-wrapper {
      bottom: 70px;
    }
    .solution-item h2 {
      font-size: 28px;
    }
    /* Sahub Success Stories Page CSS */
    .testimonials-style-six .testimonial-item .sahub-image {
      padding: 0 0 0 50px;
    }
    .testimonials-style-six .sahub-image {
      width: 100%;
    }
    .testimonials-style-six .testimonial-item .testi-sixth .author-name,
    .testimonials-style-six span.testi-job {
      padding-left: 50px;
    }
    .testimonials-style-six .testimonial-item br {
      display: none;
    }
    .sahub-masonry[data-items="3"] .masonry-item {
      width: 50%;
    }
    /* Sahub Support Page CSS */
    .contact-style-two .textright {
      text-align: left;
    }
    .contact-style-two .sahub-image img {
      width: 420px;
    }
    .contact-style-two .contact-form {
      padding: 0 0 30px;
    }
    /* Sahub Team Page CSS */
    .sahub-masonry[data-items="4"] .masonry-item {
      width: 33.333333%;
    }
    .sahub-team .sahub-masonry[data-items="4"] .masonry-item {
      width: 50%;
    }
    .mate-item .empty-circle {
      right: 35%;
    }
    .mate-item .empty-circle.bottom {
      bottom: 35%;
      left: 36%;
    }
    /* Sahub Workshops Page CSS */
    .sahub-page-title.page-title-four {
      padding: 100px 0;
    }
    .onsite-meta .row {
      margin: 0 -15px;
    }
    .onsite-meta [class*="col-"] {
      padding: 0 15px;
    }
    .onsite-meta-item {
      padding: 25px 15px;
    }
    .onsite-meta-info h4 {
      font-size: 16px;
    }
    .started-style-two .sahub-image {
      padding-bottom: 30px;
    }
    .started-style-two .sahub-image img {
      width: 400px;
    }
    .sahub-secondary,
    .sahub-primary {
      float: none;
      width: 100%;
    }
    .right-sidebar .sahub-secondary {
      padding-left: 15px;
    }
    .left-sidebar .sahub-secondary {
      padding-right: 15px;
    }
    .sahub-secondary {
      margin-top: 90px;
      margin-bottom: 50px;
    }
    .left-sidebar .sahub-primary {
      float: none;
    }
    .left-sidebar .sahub-primary {
      -ms-flex-order: 1;
      order: 1;
    }
    .left-sidebar .sahub-secondary {
      -ms-flex-order: 2;
      order: 2;
    }
    .guide-item {
      max-width: 373px;
      margin: 0 auto;
      padding-bottom: 20px;
    }
    .sahub-mid-wrap,
    .sahub-mid-wrap.sahub-post-single {
      padding: 0px 0 50px;
    }
    .author-content {
      width: 100%;
    }
    .sahub-author-info {
      text-align: center;
      margin-bottom: 30px;
    }
    .author-avatar {
      width: 100%;
    }
    .news-item {
      margin-bottom: 45px;
    }
    .news-info h3 {
      line-height: 1.2;
      font-size: 27px;
      padding-top: 3px;
    }
    .news-details h2 {
      font-size: 24px;
    }
    .news-details h3 {
      font-size: 22px;
    }
    .news-details h4 {
      font-size: 20px;
    }
    .news-details h5 {
      font-size: 18px;
    }
    .news-details h6 {
      font-size: 17px;
    }
    .sahub-news-detail .news-info {
      padding-bottom: 0;
    }
    .news-cat {
      width:100%;
    }
    .news-date-wrap {
      left:0;
      right:auto;
      float:left;
      position:relative;
      margin-top: 10px;
    }
    span.blog-item-month {
      float: left;
      margin-top: 2px;
      padding-left: 5px;
    }
    .sahub-news-detail .news-date-wrap {
      float: left;
      position: relative;
      left: 0;
      right: auto;
      width:auto;
    }
    .sahub-news-detail .news-date-wrap .news-date {
      padding-left: 0;
      padding-bottom: 0;
    }
    .sahub-news-detail .news-title-wrap {
      width: 100%;
    }
    .news-info .news-cat {
      width:100%;
    }
    .news-item .news-info p {
      width:100%;
    }
    .news-item .news-info {
      padding-bottom: 17px;
    }
    .woocommerce-account .col2-set .col-1, 
    .woocommerce-account .col2-set .col-2 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .woocommerce form.login, 
    .woocommerce form.register {
      margin-top: 10px;
    }
    .sahub-banner.banner-style-two.banner-style-four {
      background-color: #e4e9ff;
    }
  
  }
  
  @media screen and (max-width:782px) {
    .admin-bar .sahub-transparent-header .sticky-wrapper,
    .admin-bar .is-sticky .sahub-header {
      top: 46px !important;
    }
  }
  
  @media screen and (max-width:600px) {
    .admin-bar .is-sticky .sahub-header {
      top: 0 !important;
    }
  }
  @media screen and (max-width:768px) {
    .woocommerce .select2-container--default .select2-selection--single {
      text-align: left;
    }
    .woocommerce .col2-set .col-1, 
    .woocommerce-page .col2-set .col-1, 
    .woocommerce .col2-set .col-2, 
    .woocommerce-page .col2-set .col-2 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .woocommerce-shipping-fields {
      margin-top: 30px;
    }
  }
  @media screen and (max-width:767px) {
    /* Sahub Normal CSS */
    h1,
    .h1 {
      font-size: 30px;
    }
    h2,
    .h2 {
      font-size: 26px;
    }
    h3,
    .h3 {
      font-size: 20px;
    }
    textarea,
    textarea.form-control,
    .profit-form-wrap textarea,
    .profit-form-wrap textarea.form-control,
    .workshops-form form textarea {
      height: 130px;
    }
    .sahub-responsive-table {
      white-space: nowrap;
    }
    .table thead th {
      padding: 16px 20px;
      font-size: 16px;
    }
    .table td {
      padding: 16px 20px;
      font-size: 14px;
    }
    .modal-dialog {
      margin: 50px 25px;
    }
    .sahub-transparent-header .sahub-header {
      padding: 0 25px;
    }
    .mean-container .header-btns {
      display: none;
    }
    .banner-caption {
      padding-bottom: 25px;
    }
    .banner-caption h1 {
      font-size: 34px;
      line-height: 40px;
    }
    .banner-label {
      padding-top: 10px;
    }
    .sahub-banner .sahub-image img {
      width: 350px;
    }
    .sahub-client {
      padding: 30px 0 20px;
    }
    .client-title-wrap h2 {
      font-size: 26px;
      line-height: 34px;
    }
    .client-title-wrap {
      padding-bottom: 15px;
    }
    .client-item {
      padding-bottom: 30px;
    }
    .feature-item {
      padding-bottom: 50px;
    }
    .feature-item .sahub-image img {
      width: 350px;
    }
    .feature-info {
      padding-top: 25px;
    }
    .feature-info .section-title-wrap {
      padding-bottom: 10px;
    }
    .feature-info .section-title {
      padding-bottom: 8px;
    }
    .section-title-wrap h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .section-title-wrap .sahub-icon {
      padding-bottom: 8px;
    }
    .owl-carousel.nav-style-two .owl-nav {
      padding-top: 15px;
    }
    .owl-carousel.nav-style-two .owl-nav button.owl-prev,
    .owl-carousel.nav-style-two .owl-nav button.owl-next {
      margin: 0 5px;
      position: static;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
    .section-title-wrap p,
    .about-info p,
    .value-item p,
    .history-item p,
    .page-title-wrap p,
    .advantage-info p,
    .alternative-item p,
    .app-integration-info p,
    .benefit-item p,
    .career-detail-item p,
    .contact-item p,
    .entry-content p,
    .trusted-item p,
    .customer-detail-wrap p,
    .features-style-two .feature-item p,
    .sahub-customer-info p,
    .technology-item p,
    .promo-info p,
    .features-style-four .feature-item p,
    .full-page-info p,
    .card-body p,
    .faq-item p,
    .guide-info p,
    .banner-caption p,
    .tool-item p,
    .compare-item p,
    .blog-item p,
    .award-info p,
    .integration-info p,
    .it-info p,
    .profit-item p,
    .investor-info p,
    .price-inner p,
    .price-meta p,
    .question-info p,
    .team-label p,
    .product-item p,
    .product-info p,
    .doc-info p,
    .security-info p,
    .testimonials-style-five .testimonial-item p,
    .solution-item p,
    .story-info p,
    .onsite-item p {
      font-size: 16px;
      line-height: 24px;
    }
    .sahub-blog .section-title-wrap {
      padding-bottom: 0;
    }
    .blog-item {
      padding: 30px 25px;
    }
    .blog-item .sahub-image {
      padding-bottom: 10px;
    }
    .sahub-blog .owl-nav {
      position: static;
      text-align: center;
    }
    p.comment-form-cookies-consent {
      display: inline-block;
      width: auto;
      float: left;
    }
    .comment-form-cookies-consent label {
      display: inline;
      padding-top:2px;
    }
    p.comment-form-cookies-consent input[type=checkbox] {
      top:1px;
    }
    .sahub-testimonials {
      padding-bottom: 10px;
    }
    .testimonial-item .sahub-image {
      padding: 10px 0 0;
    }
    .testimonial-item h4 {
      font-size: 16px;
    }
    .testimonial-item p {
      font-size: 18px;
      line-height: 28px;
    }
    .author-name {
      padding-top: 5px;
    }
    .owl-carousel .owl-dots {
      padding-top: 20px;
    }
    .skills-wrap {
      padding: 30px 25px 10px;
    }
    .skills-wrap .section-title-wrap {
      padding-bottom: 10px;
    }
    .skill-item {
      padding-bottom: 20px;
    }
    .skill-item h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .skill-item .sahub-icon {
      padding-bottom: 10px;
    }
    .sahub-callout.callout-style-two {
      padding: 0 0 50px;
    }
    .callout-wrap .section-title-wrap {
      padding-bottom: 15px;
    }
    .callout-wrap input[type="email"] {
      width: 100%;
    }
    .callout-label {
      padding-top: 15px;
    }
    .footer-widget {
      padding-bottom: 15px;
    }
    .footer-widget-title {
      padding-bottom: 10px;
    }
    .sahub-copyright {
      padding: 25px 0 20px;
    }
    .sahub-copyright ul {
      font-size: 14px;
    }
    .section-subtitle,
    .section-title {
      padding-bottom: 8px;
    }
    .sahub-social.rounded a {
      margin: 0 5px 8px 0;
    }
    .sahub-page-title {
      padding: 50px 0 40px;
    }
    .sahub-transparent-header .sahub-page-title {
      padding: 90px 0 30px;
    }
    .page-title-wrap h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .page-title {
      padding-bottom: 5px;
    }
    .check-list {
      font-size: 16px;
    }
    .check-list li {
      padding: 0 0 10px 25px;
    }
    .check-list li:before {
      font-size: 15px;
    }
    .sahub-btn-wrap,
    .blog-item .sahub-btn-wrap {
      padding-top: 10px;
    }
    .section-title-wrap {
      padding-bottom: 15px;
    }
    .services-wrap.tab-style-one .section-title-wrap {
      padding-bottom: 15px;
    }
    .services-wrap .nav-tabs .nav-item {
      padding-bottom: 15px;
    }
    .btn-link {
      padding: 10px 35px 10px 0;
      font-size: 18px;
    }
    .accordion-icon {
      background-size: 19px auto;
    }s
    .sahub-filters {
      width: 511px;
      bottom: -36px;
    }
    .sahub-filters .nav-tabs .nav-link {
      padding: 20px;
      font-size: 12px;
    }
    .sahub-filters .nav-tabs:after {
      width: 54px;
      height: 54px;
      line-height: 48px;
    }
    .services-wrap .nav-tabs .nav-item {
      width: 100%;
    }
    .sahub-it-sector [class*="col-"] {
      width: 100%;
    }
    .sahub-it-sector.sahub-freelance .sahub-background:after,
    .sahub-it-sector.sahub-freelance .order-lg-2 .sahub-background:after {
      border-radius: 0;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center center;
      left: 0;
      right: 0;
    }
    .sahub-it-sector.sahub-freelance .sahub-background:after, 
    .sahub-it-sector.sahub-freelance .order-lg-2 .sahub-background:after {
      background-size: cover;
    }
    .workflow-item {
      width: 100%;
      padding-bottom: 15px;
    }
    /* Sahub Home V2 Page CSS */
    .sahub-banner.banner-style-two {
      height: auto;
    }
    .features-style-two .feature-item {
      padding: 25px 20px;
    }
    .features-style-two .feature-item .sahub-icon {
      padding-bottom: 20px;
    }
    .feature-icons {
      padding-top: 15px;
    }
    .sahub-client.client-spacer-two {
      padding-bottom: 15px;
    }
    .sahub-services .section-title-wrap {
      padding-bottom: 20px;
    }
    .services-wrap ul.nav-tabs {
      margin-bottom: 0;
    }
    .services-wrap .nav-justified .nav-item {
      width: 50%;
      padding-bottom: 20px;
      flex-basis: auto;
      flex-grow: inherit;
    }
    .services-wrap .tab-content {
      padding-bottom: 30px;
    }
    .services-wrap.tab-style-two .tab-content {
      padding-bottom: 0;
    }
    .services-wrap .nav-link .sahub-icon {
      width: 93px;
      height: 93px;
    }
    .services-wrap .nav-link .sahub-icon img {
      width: 50px;
    }
    .price-item.style-four .price-inner h2 {
      font-size: 60px;
      line-height: 48px;
    }
    .service-title {
      padding-bottom: 10px;
      line-height: 1.2;
    }
    .sahub-testimonials.testimonials-style-two {
      padding: 30px 0 50px;
    }
    .testimonials-style-two .sahub-image {
      padding-top: 0;
    }
    .testimonial-info .author-name {
      padding-top: 0;
    }
    .sahub-started .section-title-wrap {
      padding-bottom: 15px;
    }
    .map-locations {
      display: none;
    }
    .world-item {
      margin-bottom: 30px;
    }
    .sahub-awards .section-title-wrap {
      padding-bottom: 5px;
    }
    .award-item {
      padding: 30px 20px;
    }
    .sahub-integration .section-title-wrap {
      padding-bottom: 25px;
    }
    .integration-image {
      padding-bottom: 35px;
    }
    .sahub-link-wrap {
      padding-top: 5px;
    }
    .integration-item {
      padding-bottom: 25px;
    }
    .integration-image img {
      width: 300px;
    }
    .integration-info h3 {
      font-size: 20px;
    }
    .sahub-support h2 {
      font-size: 24px;
      line-height: 34px;
    }
    .support-main-title {
      padding-bottom: 25px;
    }
    .support-item h3 {
      font-size: 20px;
    }
    .sahub-footer.footer-style-two .footer-wrap {
      padding: 50px 0 30px;
    }
    .footer-style-two .footer-widget-title {
      padding-bottom: 10px;
    }
    .footer-style-two .footer-contact {
      padding-bottom: 0;
    }
    .footer-logos {
      padding: 25px 0;
    }
    .widget-demo {
      padding: 30px 25px;
      margin-top: 10px;
    }
    .widget-demo .footer-widget-title {
      padding-bottom: 15px;
    }
    .integration-item.sahub-hover .sahub-icon {
      padding-top: 0;
    }
    /* Sahub 404 Page CSS */
    .error-wrap h1 {
      font-size: 120px;
      line-height: 100px;
    }
    .error-wrap h2 {
      font-size: 30px;
      line-height: 40px;
    }
    .error-wrap p {
      font-size: 18px;
    }
    .error-title {
      padding-bottom: 5px;
    }
    .error-subtitle {
      padding-bottom: 10px;
    }
    .error-wrap .sahub-btn-wrap {
      padding-top: 15px;
    }
    /* Sahub Aboutus Page CSS */
    .sahub-about {
      text-align: center;
    }
    .sahub-about .sahub-image img {
      width: 350px;
    }
    .about-info {
      padding-top: 15px;
    }
    .about-info h2 {
      font-size: 32px;
      line-height: 40px;
    }
    .about-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    .about-title {
      padding-bottom: 5px;
    }
    .sahub-values .section-title-wrap,
    .sahub-history .section-title-wrap {
      padding-bottom: 20px;
    }
    .value-item {
      padding: 30px 25px 20px;
    }
    .value-item .sahub-icon {
      padding-bottom: 15px;
    }
    .sahub-history .owl-carousel .owl-nav {
      top: -48px;
    }
    /* Sahub Advantages Page CSS */
    .advantage-title {
      padding-bottom: 0;
    }
    .advantage-info .check-list {
      padding-top: 5px;
    }
    .advantage-info p {
      line-height: 24px;
    }
    .advantage-item {
      padding-bottom: 35px;
    }
    .advantage-item .sahub-image {
      padding-bottom: 20px;
    }
    .advantage-item .sahub-image img {
      width: 350px;
    }
    /* Sahub All Apps Page CSS */
    .sahub-apps {
      padding: 50px 0 40px;
    }
    .app-info .sahub-icon,
    .sahub-webapp .app-info .sahub-icon {
      padding-bottom: 15px;
    }
    .app-image .sahub-image,
    .apps-gray-background .app-image .sahub-image {
      padding-bottom: 20px;
    }
    .app-info h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .app-info p {
      padding-bottom: 10px;
      font-size: 16px;
      line-height: 24px;
    }
    .app-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    .app-title {
      padding-bottom: 8px;
    }
    .sahub-webapp .app-info {
      padding-top: 25px;
    }
    /* Sahub Alternatives Page CSS */
    .sahub-alternatives {
      padding: 50px 0 15px;
    }
    .alternative-item {
      padding: 30px 25px 20px;
    }
    .alternative-item .sahub-icon {
      padding-bottom: 5px;
    }
    .alternative-title {
      padding-bottom: 5px;
    }
    /* Sahub App Integration Page CSS */
    .app-integration-item {
      text-align: center;
    }
    .integration-tools {
      max-width: 400px;
      margin: 0 auto;
    }
    [class*="order-"] .integration-tools {
      margin: 0 auto;
    }
    .integration-tool-item .sahub-icon img {
      width: 70px;
    }
    .integration-tool-item h3 {
      font-size: 18px;
    }
    .app-integration-info,
    [class*="order-"] .app-integration-info {
      padding: 25px 0 0;
    }
    .app-integration-info h2 {
      font-size: 28px;
      line-height: 34px;
    }
    .app-integration-title {
      padding-bottom: 5px;
    }
    .app-integration-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    .sahub-build .sahub-image img {
      width: 400px;
    }
    .build-info .section-title-wrap {
      padding-top: 15px;
    }
    /* Sahub Brand Kit Page CSS */
    .page-title-wrap .sahub-btn-wrap {
      padding-top: 10px;
    }
    .logo-label {
      padding-top: 10px;
      font-size: 22px;
    }
    .brand-kits-wrap {
      padding-top: 30px;
    }
    .brand-kit-item {
      padding: 25px 20px 10px;
    }
    .kit-download-wrap {
      padding-top: 10px;
    }
    .brand-kits-wrap .sahub-btn-wrap {
      padding-top: 0;
    }
    .brand-color-item h5 {
      font-size: 16px;
      line-height: 24px;
    }
    .brand-type-item {
      height: 150px;
      margin-bottom: 30px;
    }
    /* Sahub Careers Page CSS */
    .careers-wrap {
      padding-top: 30px;
    }
    .careers-wrap .sahub-btn-wrap {
      padding-top: 15px;
    }
    .sahub-effort .sahub-image img {
      width: 350px;
    }
    .effort-info .section-title {
      padding-bottom: 5px;
    }
    .effort-info .section-title-wrap {
      padding-top: 20px;
    }
    .effort-info .section-title-wrap p {
      line-height: 24px;
    }
    .effort-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    .sahub-benefits .section-title-wrap {
      padding-bottom: 15px;
    }
    .benefit-item {
      padding: 25px 30px 20px;
    }
    .benefit-item h3 {
      font-size: 18px;
    }
    .benefit-title {
      padding-bottom: 5px;
    }
    .process-item {
      padding-bottom: 20px;
    }
    .process-item .sahub-icon {
      width: 90px;
      height: 90px;
    }
    .process-item .sahub-icon img {
      width: 50px;
    }
    .sahub-process .section-title-wrap {
      padding-bottom: 20px;
    }
    .what-we-image {
      padding-bottom: 25px;
    }
    .what-we-image img {
      width: 400px;
    }
    .sahub-what-we .section-title-wrap {
      padding-bottom: 20px;
    }
    .what-we-item {
      padding: 18px 20px;
    }
    .what-we-item h3 {
      font-size: 16px;
    }
    .what-we-item .sahub-icon {
      display: block;
      padding: 0 0 10px;
    }
    /* Sahub Careers Single Page CSS */
    .career-meta {
      padding-bottom: 15px;
      margin-bottom: 20px;
    }
    .career-meta h4 {
      font-size: 16px;
    }
    .career-meta-title {
      padding-bottom: 5px;
    }
    .career-detail-title {
      padding-bottom: 5px;
    }
    .career-detail-item {
      padding-bottom: 15px;
    }
    .career-detail-item p {
      padding-bottom: 10px;
    }
    .career-detail-title.title-spacer {
      padding-bottom: 8px;
    }
    .career-detail-item .check-list {
      padding-bottom: 10px;
    }
    .career-detail-form {
      padding-top: 10px;
    }
    .career-detail-form textarea,
    .career-detail-form textarea.form-control {
      height: 130px;
    }
    .sahub-sidebar {
      padding-bottom: 30px;
    }
    .sahub-share {
      margin-top: 10px;
    }
    /* Sahub Change Log Page CSS */
    .log-sidebar .nav-tabs .nav-link {
      padding-left: 20px;
      font-size: 16px;
    }
    .log-title {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .log-item {
      padding-bottom: 20px;
    }
    .log-item p {
      line-height: 24px;
    }
    .bullet-list {
      font-size: 16px;
    }
    .bullet-list li {
      padding: 0 0 10px 20px;
    }
    .bullet-list li:before {
      font-size: 16px;
    }
    .log-item .bullet-list {
      padding-top: 5px;
    }
    /* Sahub Contactus Page CSS */
    .contact-form .section-title-wrap {
      padding-bottom: 10px;
    }
    .contact-form textarea,
    .contact-form textarea.form-control {
      height: 120px;
    }
    .contact-wrap {
      padding-top: 25px;
    }
    .contact-item {
      padding-bottom: 30px;
    }
    .contact-item .sahub-icon {
      margin-bottom: 10px;
    }
    .contact-item .sahub-btn-wrap {
      padding-top: 10px;
    }
    .sahub-office .section-title-wrap {
      padding-bottom: 20px;
    }
    .client-label {
      padding-bottom: 20px;
    }
    /* Sahub Content Cross Check Page CSS */
    .sahub-unit-fix .entry-content .comment-list article,
    .sahub-unit-fix .entry-content .comment-list .pingback,
    .sahub-unit-fix .entry-content .comment-list .trackback {
      padding: 15px 0 20px;
    }
    .entry-content dd {
      margin-bottom: 10px;
    }
    .wp-caption {
      max-width: 100%;
      margin-bottom: 10px;
    }
    .entry-content .sahub-responsive-table {
      white-space: nowrap;
    }
    .entry-content .comment-list article,
    .entry-content .comment-list .pingback,
    .entry-content .comment-list .trackback {
      padding: 20px 0;
    }
    .entry-content .comment-content ul,
    .entry-content .comment-content ol {
      margin: 0 0 10px 0;
    }
    .entry-content .comment-metadata {
      margin-bottom: 5px;
    }
    .entry-content .comment-list .comment-reply-link {
      margin-top: 0;
    }
    .entry-content .comment-list .children {
      padding-left: 0;
    }
    .entry-content .comment-notes,
    .entry-content .comment-awaiting-moderation,
    .entry-content .logged-in-as,
    .entry-content .form-allowed-tags {
      padding-bottom: 10px;
    }
    .comment-subscription-form label {
      font-size: 12px;
    }
    /* Sahub Core Values Page CSS */
    .sahub-masonry .masonry-item {
      float: none;
      width: 100%;
    }
    .core-value-count {
      font-size: 50px;
      line-height: 56px;
    }
    .core-value-info h2 {
      font-size: 24px;
      line-height: 32px;
    }
    .sahub-customers .section-title-wrap {
      padding-bottom: 15px;
    }
    .customer-item {
      padding: 30px;
      margin: 0;
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;
    }
    .customer-item .sahub-icon {
      padding-bottom: 10px;
    }
    .customer-item p {
      font-size: 18px;
      line-height: 26px;
    }
    .customer-info {
      padding-top: 10px;
    }
    .customer-inner-info {
      padding-left: 15px;
    }
    .customer-inner-info h4 {
      font-size: 16px;
    }
    /* Sahub Customers Page CSS */
    .trusted-item-wrap {
      padding-bottom: 10px;
    }
    .sahub-trusted h2 {
      font-size: 24px;
    }
    .trusted-title {
      padding-bottom: 13px;
    }
    .trusted-item {
      padding: 30px 25px;
    }
    .trusted-item .sahub-image {
      padding-bottom: 10px;
    }
    /* Sahub Customers Single Page CSS */
    .sahub-customer-detail {
      padding: 50px 0 40px;
    }
    .customer-meta {
      padding-bottom: 0;
    }
    .customer-meta h3 {
      font-size: 20px;
    }
    .customer-meta-item {
      padding-bottom: 25px;
    }
    .features-style-three .section-title-wrap {
      padding-bottom: 20px;
    }
    .sahub-customer-info {
      padding: 50px 0 40px;
    }
    .customer-info-item {
      padding-bottom: 10px;
    }
    .customer-info-item h2 {
      font-size: 22px;
    }
    .customer-info-title {
      padding-bottom: 5px;
    }
    .sahub-quotes {
      margin: 25px 0 30px;
    }
    .quote-info {
      min-height: 10px;
      padding: 30px 25px;
    }
    .quote-info p {
      font-size: 18px;
      line-height: 28px;
    }
    .quote-info h4 {
      font-size: 16px;
    }
    .quote-info .sahub-icon {
      padding-bottom: 15px;
    }
    .quote-name {
      padding: 0;
    }
    /* Sahub Designing Page CSS */
    .technology-image img {
      width: 350px;
    }
    .technology-wrap {
      padding-top: 30px;
    }
    .technology-title {
      padding-bottom: 5px;
    }
    .technology-item {
      padding-bottom: 25px;
    }
    .promo-info,
    [class*="order-"] .promo-info {
      padding-top: 20px;
    }
    .promo-info h2 {
      font-size: 26px;
      line-height: 34px;
    }
    .promo-subtitle,
    .promo-title {
      padding-bottom: 5px;
    }
    .promo-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    .features-style-four .section-title-wrap {
      padding-bottom: 20px;
    }
    .features-style-four .feature-item {
      padding: 25px 25px 20px;
    }
    .features-style-four .feature-item .sahub-icon {
      margin-bottom: 15px;
    }
    .features-style-four .feature-title {
      padding-bottom: 5px;
    }
    /* Sahub E Book Page CSS */
    .full-page-wrap .sahub-header {
      padding: 15px 35px;
    }
    .full-page-wrap .sahub-header .sahub-toggle {
      margin-left: 0;
    }
    .fullscreen-navigation .navigation-wrap {
      padding-left: 25px;
      padding-right: 25px;
    }
    .full-page-info h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .full-page-subtitle {
      padding-bottom: 10px;
    }
    .full-page-inner {
      padding: 10px 25px 25px;
    }
    .full-page-wrap .sahub-technology {
      padding-top: 10px;
    }
    .full-page-wrap .technology-wrap {
      padding-top: 25px;
    }
    .full-page-wrap .technology-item p {
      line-height: 24px;
    }
    .sahub-ebook {
      padding: 50px 25px;
    }
    .ebook-info input[type="submit"] {
      min-width: 100%;
      margin-top: 0;
    }
    .ebook-info .sahub-icon {
      padding-bottom: 20px;
    }
    .ebook-info .sahub-icon img {
      width: 160px;
    }
    .ebook-info .section-title-wrap {
      padding-bottom: 15px;
    }
    .full-page-title {
      padding-bottom: 8px;
    }
    .technology-item h3 {
      font-size: 18px;
    }
    /* Sahub Faq Page CSS */
    .sahub-questions {
      padding: 50px 0 40px;
    }
    .questions-search h2 {
      font-size: 22px;
      line-height: 32px;
    }
    .questions-title {
      padding-bottom: 20px;
    }
    .questions-title br {
      display: none;
    }
    .questions-search input[type="text"] {
      width: 320px;
    }
    .questions-wrap {
      padding-top: 30px;
    }
    .questions-wrap h2 {
      font-size: 22px;
    }
    .questions-wrap .questions-title {
      padding-bottom: 10px;
    }
    .questions-labels {
      padding-top: 25px;
    }
    .sahub-faq .section-title-wrap {
      padding-bottom: 20px;
    }
    .faq-item .sahub-icon {
      padding-bottom: 10px;
    }
    /* Sahub Faq Page CSS */
    .feature-item-wrap {
      padding-bottom: 10px;
    }
    .feature-item-wrap .section-title-wrap {
      padding-bottom: 10px;
    }
    /* Sahub Get Started Guide Page CSS */
    .sahub-guide {
      text-align: center;
    }
    .sahub-guide .section-title-wrap {
      padding-bottom: 10px;
      text-align: center;
    }
    .guide-info {
      padding-top: 15px;
    }
    .guide-info h3 {
      font-size: 20px;
    }
    /* Sahub Integration Single Page CSS */
    .sahub-logo-link {
      bottom: -28px;
    }
    .sahub-logo-link .sahub-icon {
      width: 60px;
      height: 60px;
    }
    .sahub-logo-link .sahub-icon img {
      width: 30px;
    }
    .sahub-integration-single .promo-item + .promo-item {
      padding-top: 50px;
    }
    .sahub-integration-single .promo-item .sahub-image img {
      width: 450px;
    }
    .sahub-integration-single .promo-info,
    .sahub-integration-single [class*="order-"] .promo-info {
      padding-top: 15px;
    }
    /* Sahub It Page CSS */
    .sahub-background {
      min-height: 300px;
    }
    .it-info {
      padding: 50px 0 40px;
    }
    .it-info h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .it-title {
      padding-bottom: 8px;
    }
    .it-subtitle {
      padding-bottom: 10px;
    }
    .it-info .check-list {
      padding-top: 10px;
      font-size: 16px;
    }
    .it-info .check-list li {
      padding: 0 0 8px 25px;
    }
    /* Sahub No Openings Page CSS */
    .sahub-vacant {
      padding: 50px 0 40px;
    }
    .vacant-wrap .sahub-icon {
      padding-bottom: 20px;
    }
    .vacant-wrap .sahub-icon img {
      width: 280px;
    }
    .vacant-wrap h2 {
      font-size: 30px;
      line-height: 38px;
    }
    .vacant-wrap p {
      font-size: 18px;
      line-height: 28px;
    }
    .vacant-wrap br {
      display: none;
    }
    /* Sahub Partner Page CSS */
    .sahub-profit .section-title-wrap,
    .sahub-investors .section-title-wrap,
    .sahub-profit-form .section-title-wrap {
      padding-bottom: 20px;
    }
    .profit-item {
      padding: 30px 25px 20px;
      margin: 0 0 30px;
    }
    .profit-item .sahub-icon {
      padding-bottom: 10px;
    }
    .profit-item h3 {
      font-size: 20px;
    }
    .profit-title {
      padding-bottom: 5px;
    }
    .investor-item {
      max-width: 360px;
      margin: 0 auto 30px;
    }
    .investor-info {
      padding: 0 25px 20px;
    }
    .investor-title {
      padding-bottom: 5px;
    }
    /* Sahub Pricings 01 Page CSS */
    .sahub-page-title.page-title-spacer {
      padding-bottom: 55px;
      margin-bottom: 20px;
    }
    .price-inner .sahub-icon {
      padding-bottom: 10px;
    }
    .price-inner h2 {
      font-size: 32px;
      line-height: 38px;
    }
    .price-inner h3 {
      font-size: 24px;
    }
    .price {
      padding: 5px 0;
    }
    .price-users {
      padding: 10px 0 15px;
    }
    .price-info {
      min-height: 10px;
    }
    .price-info .check-list li {
      padding-bottom: 10px;
    }
    .price-link {
      padding: 20px 25px;
    }
    .price-meta {
      padding-top: 0;
    }
    .sahub-compare-plans {
      padding: 50px 0 26px;
    }
    .plans-wrap .table td,
    .plans-wrap .table thead th {
      padding: 10px 15px;
    }
    .plans-wrap .table thead th,
    .plans-wrap .table thead tr th:first-child,
    .plans-wrap .table td i {
      font-size: 16px;
    }
    .plans-wrap .table td {
      font-size: 15px;
    }
    .plans-wrap .table tbody tr:first-child td {
      padding-top: 15px;
    }
    .plans-wrap .table tbody tr:last-child td {
      padding-bottom: 25px;
    }
    .sahub-compare-plans .section-title-wrap,
    .questions-style-two .section-title-wrap {
      padding-bottom: 15px;
    }
    .question-info h4 {
      font-size: 18px;
      line-height: 26px;
    }
    .question-title {
      padding-bottom: 5px;
    }
    .question-item .sahub-icon img {
      width: 22px;
    }
    .question-item {
      padding-bottom: 15px;
    }
    .question-meta {
      padding-top: 20px;
      margin-top: 5px;
    }
    .question-meta h3 {
      font-size: 20px;
    }
    .question-meta-title {
      padding-bottom: 15px;
    }
    .price-info .check-list li:before {
      top: 1px;
    }
    /* Sahub Pricings 02 Page CSS */
    .price-info-wrap {
      padding: 25px 25px 20px;
    }
    .price-item-wrap {
      padding: 30px 25px;
    }
    .price-info-wrap h3 {
      font-size: 24px;
      line-height: 32px;
    }
    .price-info-wrap ul {
      font-size: 16px;
    }
    .price-info-wrap .price-title {
      padding-bottom: 10px;
    }
    .price-item-wrap h2 {
      font-size: 38px;
      line-height: 44px;
    }
    .price-item-wrap .price-users {
      padding-bottom: 20px;
    }
    .price-item-wrap .sahub-btn-wrap {
      padding: 10px 0 15px;
    }
    .pricings-style-two .price-meta {
      padding-top: 10px;
    }
    .sahub-testimonials.testimonials-style-four {
      padding: 26px 0 45px;
    }
    /* Sahub Pricings 03 Page CSS */
    .pricings-style-three .price-users {
      margin-bottom: 15px;
    }
    .pricings-style-three .price {
      padding-bottom: 5px;
    }
    .pricings-style-three .sahub-btn-wrap {
      padding: 10px 0 15px;
    }
    .pricings-style-three .price-link {
      font-size: 13px;
    }
    .pricings-style-three .price-meta,
    .pricings-style-three .price-meta p {
      padding-top: 0;
    }
    .questions-style-three .section-title-wrap {
      padding-bottom: 10px;
    }
    .question-wrap .question-meta {
      padding-top: 25px;
    }
    /* Sahub Pricings 04 Page CSS */
    .sahub-best-price {
      padding: 50px 0 0;
    }
    .sahub-best-price .section-title-wrap {
      padding-bottom: 15px;
    }
    .team-price {
      padding: 30px 25px 35px;
    }
    .team-price h2 {
      font-size: 36px;
      line-height: 44px;
    }
    .team-price .sahub-btn-wrap {
      padding-top: 10px;
    }
    .team-label {
      padding-top: 15px;
    }
    .plan-includes {
      max-width: none;
      padding: 35px 0 10px;
      margin: 30px 0 25px;
    }
    .includes-title {
      text-align: left;
    }
    .plan-includes h3 {
      font-size: 22px;
    }
    .includes-title {
      padding-bottom: 15px;
    }
    .plan-includes .check-list li {
      padding-bottom: 10px;
    }
    .plan-includes .check-list {
      padding-bottom: 15px;
    }
    .plan-label {
      font-size: 13px;
    }
    /* Sahub Product Page CSS */
    .product-image {
      padding-bottom: 25px;
    }
    .product-image img {
      width: 450px;
    }
    .product-info-wrap {
      padding-bottom: 35px;
    }
    .product-info .sahub-image {
      padding-bottom: 10px;
    }
    .product-info .sahub-image img {
      width: 210px;
    }
    .product-item {
      padding-bottom: 20px;
    }
    .product-item h3 {
      font-size: 20px;
    }
    .product-item .sahub-icon {
      margin-bottom: 15px;
      width: 46px;
      height: 46px;
      font-size: 16px;
      line-height: 48px;
    }
    .product-item .bullet-list {
      padding-top: 0;
      line-height: 22px;
    }
    .product-meta h4 {
      font-size: 16px;
    }
    .product-device {
      font-size: 20px;
      padding-left: 0;
    }
    .product-device a {
      margin-left: 6px;
    }
    .product-item .bullet-list li::before {
      top: 8px;
    }
    /* Sahub Product Management Page CSS */
    .sahub-video {
      padding: 70px 0;
    }
    .video-wrap .video-btn {
      width: 57px;
      height: 57px;
    }
    .video-label {
      padding-top: 15px;
      font-size: 16px;
    }
    /* Sahub Product Single Page CSS */
    .sahub-doc {
      padding: 50px 0 20px;
    }
    .doc-wrap {
      padding-top: 30px;
    }
    .doc-item .sahub-icon {
      margin-top: 0;
    }
    .doc-info h3 {
      font-size: 20px;
    }
    .doc-info {
      padding-left: 20px;
    }
    .doc-title {
      padding-bottom: 5px;
    }
    .doc-item .sahub-icon {
      width: 41px;
      height: 41px;
      font-size: 15px;
      line-height: 42px;
    }
    .pricings-spacer-two .section-title-wrap {
      padding-bottom: 20px;
    }
    .plus-includes {
      padding: 0 0 20px;
    }
    .plus-includes h3 {
      font-size: 20px;
    }
    .plus-includes-title {
      padding-bottom: 10px;
    }
    /* Sahub Referal Page CSS */
    .affiliate-item .sahub-icon {
      padding: 20px;
    }
    .affiliate-info {
      padding: 30px 25px 20px;
    }
    .affiliate-info h2 {
      font-size: 28px;
      line-height: 34px;
    }
    .affiliate-info h3 {
      font-size: 20px;
    }
    .affiliate-title {
      padding-bottom: 10px;
    }
    .affiliate-title br {
      display: none;
    }
    .affiliate-inner-info {
      padding-top: 10px;
    }
    .affiliate-inner-info h4 {
      font-size: 18px;
    }
    .affiliate-inner-info p {
      padding-bottom: 10px;
    }
    .callout-style-three h2 {
      font-size: 28px;
      line-height: 38px;
    }
    .callout-style-three .sahub-btn-wrap {
      padding-top: 10px;
    }
    /* Sahub Request A Demo Page CSS */
    .sahub-watch .section-title-wrap {
      padding-bottom: 20px;
    }
    .sahub-watch .section-title-wrap h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .sahub-watch .section-title {
      padding-bottom: 5px;
    }
    .watch-form-label {
      padding: 5px 0 20px;
    }
    .watch-image {
      margin-bottom: 30px;
    }
    .watch-wrap {
      padding-top: 30px;
    }
    .watch-wrap h3 {
      font-size: 24px;
    }
    .watch-wrap .check-list {
      padding-bottom: 20px;
    }
    .watch-title {
      padding-bottom: 10px;
    }
    .watch-inner-wrap {
      padding: 25px 25px 8px;
    }
    /* Sahub Roadmap Page CSS */
    .roadmap-info {
      padding-top: 0;
    }
    .roadmap-item {
      padding-bottom: 20px;
    }
    .roadmap-item h2 {
      font-size: 24px;
      line-height: 32px;
    }
    .number-list,
    .roadmap-item .check-list,
    .roadmap-updates {
      font-size: 16px;
    }
    .number-list li,
    .roadmap-item .check-list li {
      padding: 0 0 15px 25px;
    }
    .roadmap-item .check-list li::before {
      top: 2px;
      font-size: 14px;
    }
    .roadmap-updates li {
      margin-bottom: 15px;
      background: transparent;
    }
    .roadmap-updates li:last-child {
      margin-bottom: 0;
    }
    .roadmap-updates li .fa {
      font-size: 14px;
    }
    .update-title {
      padding-top: 6px;
      display: block;
      float: none;
    }
    .update-time {
      float: none;
      padding: 4px 0 0 22px;
    }
    /* Sahub Security Page CSS */
    .security-item {
      padding: 25px;
    }
    .security-item .sahub-icon img {
      width: 60px;
    }
    .security-info h3 {
      font-size: 20px;
    }
    .security-title {
      padding-bottom: 5px;
    }
    /* Sahub Services Page CSS */
    .expert-info {
      padding-top: 20px;
    }
    .expert-info .section-title {
      padding-bottom: 8px;
    }
    .tools-style-two .section-title-wrap {
      padding-bottom: 20px;
    }
    .tools-style-two .tool-item {
      padding: 30px 25px;
    }
    .tools-style-two .tool-item .sahub-icon {
      padding-bottom: 10px;
    }
    .tools-style-two .tool-item h3 {
      font-size: 20px;
    }
    .tools-style-two .tool-title {
      padding-bottom: 5px;
    }
    .tool-icons img {
      margin-right: 20px;
    }
    .tool-icons {
      padding-top: 10px;
    }
    .tools-style-two .sahub-btn-wrap {
      padding-top: 5px;
    }
    .testimonials-style-five .testimonial-item .sahub-image {
      padding-bottom: 0;
    }
    .testimonials-style-five .testimonial-wrap {
      padding: 25px 25px 30px;
    }
    .testimonials-style-five .author-name {
      padding-top: 0;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-prev {
      left: -45px;
    }
    .testimonials-style-five .owl-carousel .owl-nav button.owl-next {
      right: -45px;
    }
    .business-image img {
      width: 350px;
    }
    .business-info {
      padding-top: 20px;
    }
    .business-info .section-title {
      padding-bottom: 5px;
    }
    .business-info .section-title-wrap p {
      padding-bottom: 10px;
    }
    .business-info .sahub-btn-wrap {
      padding-top: 10px;
    }
    /* Sahub Signin Signup Page CSS */
    .sahub-login {
      padding: 50px 0;
    }
    .login-wrap {
      padding: 30px 30px 20px;
    }
    .login-meta-wrap p {
      padding-bottom: 10px;
      font-size: 15px;
      line-height: 24px;
    }
    .meta-divide {
      padding: 0 0 10px;
    }
    .forgot-password {
      font-size: 14px;
    }
    /* Sahub Sitemap Page CSS */
    .sitemap-item {
      padding-bottom: 15px;
    }
    .sitemap-item h3 {
      font-size: 22px;
    }
    .sitemap-title {
      padding-bottom: 10px;
      margin-bottom: 15px;
    }
    .sitemap-item .bullet-list {
      padding-bottom: 10px;
      font-size: 16px;
    }
    .sitemap-item .bullet-list li {
      padding-bottom: 8px;
    }
    .sitemap-item .bullet-list li:before {
      top: 2px;
      font-size: 14px;
    }
    /* Sahub Solutions Page CSS */
    .sahub-banner.banner-style-three {
      height: 400px;
    }
    .banner-style-three .banner-caption {
      padding-bottom: 40px;
    }
    .banner-style-three .banner-caption h1 {
      font-size: 28px;
      line-height: 38px;
    }
    .banner-style-three .banner-caption h5 {
      font-size: 14px;
    }
    .banner-style-three .banner-subtitle {
      padding-bottom: 10px;
    }
    .sahub-main-banner .sticky-wrapper {
      bottom: 50px;
    }
    .solution-filter ul {
      font-size: 16px;
    }
    .solution-filter ul li {
      padding: 10px 15px;
    }
    .solution-item {
      padding-bottom: 20px;
    }
    .solution-item h2 {
      font-size: 24px;
    }
    .solutions-title {
      padding-bottom: 5px;
    }
    .solution-item p {
      padding-bottom: 10px;
    }
    .sahub-stats {
      padding-top: 15px;
    }
    .stats-item h3 {
      font-size: 22px;
    }
    .stats-item:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .solutions-brand-wrap {
      padding-top: 20px;
    }
    .solutions-brand {
      padding: 0 0 10px;
      margin-bottom: 20px;
    }
    .solutions-inner {
      padding: 15px 0 0;
    }
    .solutions-inner .sahub-icon {
      width: 41px;
      height: 41px;
      margin-top: 0;
      font-size: 15px;
      line-height: 41px;
    }
    .solutions-inner-info {
      padding-left: 15px;
    }
    .solutions-inner-info h3 {
      font-size: 20px;
    }
    .solutions-inner-title {
      padding-bottom: 5px;
    }
    .growth-chart .sahub-image {
      padding-bottom: 5px;
      margin-bottom: 15px;
    }
    .growth-chart h3 {
      font-size: 20px;
    }
    .sahub-customers.customers-style-three {
      padding: 50px 0;
    }
    .customers-style-three .customer-item {
      padding: 25px 25px 20px;
    }
    .customers-style-three .customer-item .sahub-image {
      padding-bottom: 0;
    }
    .customers-style-three .customer-item p {
      font-size: 16px;
      line-height: 26px;
    }
    .customers-style-three .customer-info {
      padding-top: 5px;
    }
    .customers-style-three .customer-info h4 {
      font-size: 16px;
    }
    .customers-style-three .customer-info h5 {
      font-size: 15px;
    }
    /* Sahub Success Stories Page CSS */
    .testimonials-style-six .testimonial-item p {
      font-size: 18px;
    }
    .sahub-story .section-title-wrap {
      padding-bottom: 20px;
    }
    .sahub-masonry[data-items="3"] .masonry-item {
      width: 100%;
    }
    .story-item {
      max-width: 360px;
      margin: 0 auto;
    }
    .story-info {
      padding: 20px 25px 25px;
    }
    .story-title {
      padding-bottom: 5px;
    }
    /* Sahub Support Page CSS */
    .sahub-support.support-spacer-two {
      padding-top: 50px;
    }
    .sahub-contact.contact-style-two {
      padding: 0 0 50px;
    }
    .contact-style-two .sahub-image img {
      width: 350px;
    }
    .contact-form-label {
      padding: 5px 0 10px;
    }
    .contact-form input[type="submit"] {
      margin-bottom: 0;
    }
    /* Sahub Team Page CSS */
    .sahub-team {
      padding: 0;
    }
    .masonry-filters {
      margin-bottom: 25px;
    }
    .sahub-team .masonry-filters {
      margin-bottom: 40px;
    }
    .masonry-filters ul li a {
      padding-bottom: 2px;
    }
    .sahub-masonry[data-items="4"] .masonry-item {
      width: 50%;
      padding-bottom: 30px;
    }
    .mate-info {
      padding-top: 15px;
    }
    .mate-info h3 {
      font-size: 18px;
    }
    .modal-lg {
      max-width: none;
    }
    .mate-info-wrap .modal-dialog {
      margin: 50px 25px;
    }
    .mate-info-item h4 {
      font-size: 16px;
    }
    .mate-info-wrap .modal-content {
      padding: 30px;
    }
    .mate-info-item {
      padding-bottom: 10px;
    }
    .mate-info-label {
      padding-bottom: 5px;
    }
    .mate-about {
      padding-top: 15px;
    }
    .mate-about .mate-info-label {
      padding-bottom: 8px;
    }
    .mate-about .sahub-btn-wrap {
      padding-top: 10px;
    }
    .mate-meta {
      margin-top: 0;
    }
    .mate-meta .sahub-social {
      padding-top: 15px;
    }
    /* Sahub Workshops Page CSS */
    .workshop-filter .nav-tabs .nav-item {
      padding: 0 20px 0 10px;
    }
    .workshop-filter .nav-tabs .nav-link {
      font-size: 16px;
    }
    .onsite-meta-item {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .sahub-workshops {
      padding: 50px 0;
    }
    .sahub-workshops .section-title-wrap {
      padding-bottom: 20px;
    }
    .workshops-form input[type="submit"] {
      margin-top: 0;
    }
    .started-style-two .sahub-image img {
      width: 300px;
    }
    .started-style-two .sahub-image {
      padding-bottom: 20px;
    }
    .onsite-wrap {
      padding-top: 0;
    }
    .onsite-item,
    .onsite-item.onsite-spacer-one {
      padding: 20px 25px 15px 60px;
    }
    .onsite-item:nth-child(2n) {
      padding: 20px 60px 15px 25px;
    }
    .onsite-item h4 {
      font-size: 18px;
    }
    .onsite-item:before {
      left: 35px;
    }
    .onsite-item:after {
      left: 35px;
      right: 35px;
    }
    .onsite-counter,
    .onsite-item.onsite-spacer-one .onsite-counter {
      top: 20px;
    }
    .onsite-counter:after {
      top: 5px;
      right: -18px;
    }
    .onsite-item:nth-child(2n):before {
      right: 35px;
    }
    .onsite-item:nth-child(2n) .onsite-counter {
      top: 21px;
    }
    .onsite-item:nth-child(2n) .onsite-counter:after {
      left: -18px;
    }
    .onsite-link {
      padding-top: 20px;
    }
    .news-bottom-metas .share-links {
      left: -36px;
    }
    .comment-form-cookies-consent label {
      padding: 1px 0 0;
    }
    .team-total-social .sahub-social {
      left:2px;
    }
    .sahub-header .col-3 {
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
      max-width: 30%;
    }
    .sahub-full-page .sahub-header .col-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .woocommerce .woocommerce-notices-wrapper .woocommerce-message .button, 
    .woocommerce-message.woocommerce-message--info .button {
      margin-top: 0;
      margin-left: 15px;
      padding: 8px 11px 7px;
    }
    .woocommerce-notices-wrapper .woocommerce-message, 
    .woocommerce-message.woocommerce-message--info {
      padding-top: 24px;
      padding: 24px 15px 20px 35px;
    }
    .woocommerce-notices-wrapper .woocommerce-message::before, 
    .woocommerce-message.woocommerce-message--info::before {
      left: 10px;
    }
    .woocommerce form.login, .woocommerce form.register {
      padding: 20px;
    }
    .news-cat .news-author img {
      margin-bottom: 8px; 
    }
  }
  
  @media screen and (max-width:575px) {
   
    .woocommerce-page table.cart td.actions .coupon .button {
      width: 100%;
    }
    .woocommerce-cart table.cart td.actions .coupon .input-text {
      min-width: 100%;
      margin-bottom: 7px;
    }
    .doc-item {
      padding-bottom: 5px;
    }
    .woocommerce .woocommerce-result-count {
      width: 100%;
      text-align: center;
    }
    .woocommerce .woocommerce-ordering {
      width: 100%;
    }
    .woocommerce div.product .woocommerce-tabs ul.tabs {
      text-align: center;
    }
    .woocommerce div.product .woocommerce-tabs ul.tabs li {
      display: inline-block;
      width: 100%;
    }
    .woocommerce div.product .woocommerce-tabs ul.tabs li a:before {
      bottom: 6px;
    }
    .banner-style-four.banner-style-two .banner-caption p {
      line-height: 26px;
    }
      .premium-section .elementor-widget.elementor-widget-heading {
      margin-top: 50px;
      }
      #menu-item-4681.menu-item-4681 {
      margin-left: 0;
  }
      .elementor-4696 .elementor-element.elementor-element-ddef82f {padding: 0px 0px 0px 0px !important;}
      .elementor-4696 .elementor-element.elementor-element-bcb4949 .elementor-heading-title {font-size: 30px !important;}
      .sahub-main-wrap .sahub-page-title {padding: 35px 0;}
  }
  
  @media screen and (max-width:575px) {
    /* Sahub Normal CSS */
    h1,
    .h1 {
      font-size: 26px;
    }
    h2,
    .h2 {
      font-size: 22px;
    }
    .container {
      padding: 0 25px;
    }
    .news-items-wrap.sahub-blog > .container {
      padding: 0;
    }
    .sahub-header [class*="col-"] {
      padding: 0;
    }
    .sahub-client .container {
      padding: 0;
    }
    .sahub-client {
      padding: 0;
    }
    .banner-caption h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .banner-title {
      padding-bottom: 5px;
    }
    .banner-caption input[type="email"] {
      width: 254px;
    }
    .sahub-banner .sahub-image img,
    .feature-item .sahub-image img {
      width: 300px;
    }
    .news-info blockquote {
      padding-bottom: 20px;
    }
    blockquote {
      margin-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .sahub-btn-wrap .sahub-btn i {
      margin-right: 7px;
      font-size: 15px;
    }
    .news-bottom-wrap.single-bottom-wrap {
      padding-top: 3px;
    }
    .news-bottom-wrap .sahub-btn-wrap {
      float: left;
    }
    .news-bottom-metas {
      padding-bottom: 35px;
    }
    .sahub-comment-form {
      padding-top: 20px;
    }
    .header-btns .sahub-btn {
      padding: 14px 18px;
    }
    .widget-posts-info .news-info {
      padding: 10px 0 3px 0;
      width: 100%;
    }
    .widget-posts .sahub-image {
      width: 34%;
      text-align: left;
    }
    .client-title-wrap h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .client-item {
      padding-bottom: 25px;
    }
    .client-item .sahub-image img {
      width: 110px;
    }
    .section-title-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }
    .testimonial-item h4 {
      font-size: 14px;
    }
    .testimonial-item p {
      font-size: 16px;
      line-height: 26px;
    }
    .author-name {
      padding-top: 0;
    }
    .callout-wrap .section-title-wrap {
      padding-bottom: 10px;
    }
    .footer-wrap ul {
      font-size: 16px;
    }
    .footer-wrap h3 {
      font-size: 22px;
    }
    .sahub-copyright ul li {
      display: block;
      padding-bottom: 8px;
    }
    .sahub-filters {
      width: 95%;
      margin: 0 auto;
      bottom: -33px;
    }
    .services-wrap .nav-link .sahub-icon {
      width: 70px;
      height: 70px;
    }
    .services-wrap .tab-link-text {
      width: 60%;
    }
    .services-wrap.tab-style-two .tab-link-text {
      width: 100%;
    }
    .services-wrap.tab-style-two ul.nav-tabs {
      padding-left: 15px;
    }
    .page-title-wrap .breadcrumb li a, .page-title-wrap .breadcrumb li span {
      font-size: 16px;
      padding-left: 3px;
      padding-right: 9px;
    }
    /* Sahub Home V2 Page CSS */
    .sahub-copyright ul li:after,
    .testimonials-style-two .owl-carousel .owl-nav,
    .sahub-awards .owl-nav {
      display: none;
    }
    .world-wrap {
      max-width: 300px;
    }
    .footer-logos {
      padding-bottom: 5px;
    }
    .footer-logos-wrap .sahub-image {
      padding-bottom: 20px;
    }
    .sahub-masonry[data-items="4"] .masonry-item {
      width: 100%;
      max-width: 390px;
    }
    .sahub-team .sahub-masonry[data-items="4"] .masonry-item {
      width: 100%;
      max-width: 390px;
    }
    .sahub-masonry[data-items="4"] {
      text-align: center;
      margin: 0 auto;
      max-width: 390px;
    }
    /* Sahub Advantages Page CSS */
    .advantage-item .sahub-image img {
      width: 280px;
    }
    /* Sahub All Apps Page CSS */
    .app-info h2 {
      font-size: 24px;
    }
    .app-image .sahub-image img {
      width: 350px;
    }
    /* Sahub App Integration Page CSS */
    .integration-tool-item {
      margin-bottom: 25px;
    }
    .integration-tool-item h3 {
      font-size: 16px;
    }
    .integration-tool-item .sahub-icon img {
      width: 50px;
    }
    /* Sahub Brand Kit Page CSS */
    .brand-color-item {
      margin: 0 18px 20px 0;
    }
    .brand-font-item {
      padding: 25px 25px 20px;
    }
    .brand-type-item .sahub-image img {
      width: 140px;
    }
    .brand-type-item {
      height: auto;
    }
    /* Sahub Careers Page CSS */
    .process-item {
      max-width: 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
    }
    .what-we-item h3 {
      font-size: 18px;
    }
    /* Sahub Contactus Page CSS */
    .contact-item {
      float: none;
      width: 100%;
      padding-right: 0;
    }
    /* Sahub Customers Page CSS */
    .trusted-title {
      text-align: center;
    }
    /* Sahub Designing Page CSS */
    .promo-info h2 {
      font-size: 24px;
      line-height: 32px;
    }
    /* Sahub Faq Page CSS */
    .questions-search input[type="text"] {
      width: 254px;
    }
    .questions-search form {
      max-width: 425px;
      margin: 0 auto;
    }
    /* Sahub E Book Page CSS */
    .full-page-info h2 {
      font-size: 22px;
      line-height: 28px;
    }
    /* Sahub Integration Single Page CSS */
    .sahub-integration-single .promo-info,
    .sahub-integration-single [class*="order-"] .promo-info {
      padding-top: 10px;
    }
    /* Sahub It Page CSS */
    .it-info h2 {
      font-size: 24px;
      line-height: 32px;
    }
    /* Sahub No Openings Page CSS */
    .vacant-wrap .sahub-icon img {
      width: 250px;
    }
    .vacant-wrap h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .vacant-wrap p {
      font-size: 16px;
      line-height: 24px;
    }
    /* Sahub Pricings 04 Page CSS */
    .plan-includes h3 {
      font-size: 20px;
    }
    /* Sahub Referal Page CSS */
    .callout-style-three h2 {
      font-size: 26px;
      line-height: 36px;
    }
    /* Sahub Request A Demo Page CSS */
    .sahub-watch .section-title-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }
    /* Sahub Services Page CSS */
    .expert-image .video-btn {
      width: 57px;
      height: 57px;
    }
    .testimonials-style-five .owl-carousel .owl-nav {
      display: none;
    }
    /* Sahub Signin Signup Page CSS */
    .login-meta .textright {
      text-align: left;
    }
    /* Sahub Solutions Page CSS */
    .banner-style-three .banner-caption h1 {
      font-size: 26px;
      line-height: 36px;
    }
    .customers-style-three .owl-carousel .owl-nav {
      display: none;
    }
    /* Sahub Success Stories Page CSS */
    .testimonials-style-six .owl-carousel .owl-nav {
      display: none;
    }
    /* Sahub Team Page CSS */
    .masonry-filters {
      font-size: 15px;
    }
    .sahub-apps-section .masonry-filters {
      margin-bottom: 40px;
    }
    .mate-meta {
      margin: 0;
      text-align: left;
    }
    .mate-meta .sahub-social {
      padding-bottom: 10px;
    }
    .mate-meta .sahub-social.rounded a {
      margin: 0 6px 10px 0;
    }
    .mate-about {
      padding-top: 5px;
    }
    /* Sahub Workshops Page CSS */
    .questions-search input[type="text"] {
      width: 100%;
      float: none;
    }
    .questions-search input[type="submit"] {
      min-width: 100%;
      margin-left: 0;
    }
    .woocommerce ul.products[class*=columns-] li.product,
    .woocommerce-page ul.products[class*=columns-] li.product {
      width: 100%;
      margin-bottom: 0;
    }
    .woocommerce-Reviews ol.commentlist {
      padding: 0;
    }
    .sahub-it-sector.sahub-freelance .it-info {
      padding: 40px 25px;
    }
    p.comment-form-cookies-consent input[type=checkbox] {
      top: 2px;
    }
    .news-title-wrap {
      width: 100%;
    }
    .sahub-news-detail .news-title-wrap {
      width:100%;
    }
    /*.news-author {
      width:100%;
      display: inline-block;
      padding-bottom: 2px;
    }*/
    .news-bottom-metas {
      width: 100%;
      padding-bottom: 0;
    }
    .news-bottom-wrap .sahub-btn-wrap {
      width:100%;
      margin-top: 10px;
    }
    .news-bottom-metas .news-share {
      padding-top: 22px;
    }
    .testimonials-style-six .testimonial-item .testi-sixth .author-name, 
    .testimonials-style-six span.testi-job {
      padding-left: 35px;
    }
    .testimonials-style-six .testimonial-item .sahub-image {
      padding-left: 35px;
    }
    .testimonials-style-six .testimonial-item.test-content p {
      padding-left: 35px;
    }
    .price-item.style-four .price-info {
      padding: 35px 30px 20px;
    }
  
  }
  
  @media screen and (max-width:500px) {
   .news-bottom-metas {
      width:100%;
      padding-bottom: 0;
    }
    .news-title-wrap {
      width: 100%;
    }
    .news-bottom-wrap.single-bottom-wrap .news-bottom-metas{
      margin-top: 0px;
    }
  
  }  
  
  @media screen and (max-width:479px) {
    /* Sahub Normal CSS */
    .banner-caption h1 {
      font-size: 24px;
      line-height: 32px;
    }
    .banner-caption input[type="email"] {
      width: 100%;
    }
    .banner-caption input[type="submit"] {
      min-width: 100%;
      margin-left: 0;
    }
    .banner-style-two .banner-caption input[type="submit"] {
      position: relative;
      left: 0;
    }
    .banner-style-four span.sahub-free-trail-txt:before {
      top: -30px;
    }
    .client-title-wrap {
      padding-bottom: 5px;
    }
    .client-title-wrap h2 {
      font-size: 22px;
    }
    .service-title {
      font-size: 20px;
    }
    .section-title-wrap h2 {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 15px;
    }
    .compare-item h3 {
      font-size: 20px;
    }
    .compare-title {
      padding-bottom: 5px;
    }
    .sahub-blog .section-title-wrap {
      padding-bottom: 10px;
    }
    .sahub-blog .owl-carousel.nav-style-two .owl-nav {
      padding-top: 20px;
    }
    .tool-item,
    .blog-item {
      margin: 0;
      border-color: #dcdced;
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none;
    }
    .nav-style-two .blog-item .slider-blog-info {
      border: 1px solid #dcdced;
    }
    .owl-item .blog-item {
      margin: 0 7px;
    }
    .slider-blog-info {
      padding: 15px;
    }
    .blog-item .slider-blog-info h3 {
      font-size: 18px;
      line-height: 22px;
    }
    .blog-title {
      padding-bottom: 5px;
    }
    .callout-wrap input[type="email"] {
      width: 100%;
    }
    .callout-wrap input[type="submit"] {
      min-width: 100%;
      margin: 15px 0 0;
      position: relative;
    }
    .page-title-wrap h1 {
      font-size: 28px;
      line-height: 36px;
    }
    .card-body {
      padding: 0px 0 22px;
    }
    .features-style-four .feature-item h3 {
      font-size: 18px;
    }
    .sahub-filters .nav-tabs .active-bg {
      width: 50%;
    }
    .nav-tabs > li.active:nth-child(2n) ~ .active-bg {
      left: 50%;
    }
    .sahub-filters .nav-tabs:after {
      display: none;
    }
    .callout-wrap form p {
      display: block;
    }
    /* Sahub Home V1 Page CSS */
    .tool-item .sahub-icon {
      padding-bottom: 10px;
    }
    /* Sahub Home V2 Page CSS */
    .services-wrap .nav-tabs .nav-link {
      font-size: 16px;
    }
    .testimonials-style-two .testimonial-item h4 {
      font-size: 16px;
    }
    .sahub-integration {
      text-align: center;
    }
    .integration-item .sahub-icon {
      float: none;
      margin: 0 auto;
      text-align: center;
    }
    .integration-info {
      padding: 15px 0 0;
      text-align: center;
    }
    .sahub-support h2 {
      font-size: 20px;
      line-height: 30px;
    }
    .sahub-support br {
      display: none;
    }
    .integration-image {
      padding-bottom: 25px;
    }
    .widget-demo {
      padding-left: 20px;
      padding-right: 20px;
    }
    .support-title {
      padding-bottom: 10px;
    }
    /* Sahub 404 Page CSS */
    .error-wrap h1 {
      font-size: 100px;
      line-height: 90px;
    }
    .error-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }
    .error-wrap p {
      font-size: 16px;
      line-height: 26px;
    }
    .error-wrap .sahub-btn-wrap {
      padding-top: 10px;
    }
    /* Sahub Aboutus Page CSS */
    .about-info h2 {
      font-size: 28px;
      line-height: 36px;
    }
    .history-info {
      padding-right: 0;
    }
    .sahub-history .owl-carousel .owl-nav {
      top: -42px;
    }
    .sahub-history .owl-carousel .owl-nav button.owl-prev,
    .sahub-history .owl-carousel .owl-nav button.owl-next {
      width: 10px;
      background-size: 20px auto;
    }
    .history-item h4 {
      font-size: 18px;
    }
    .history-year {
      padding-bottom: 25px;
      margin-bottom: 15px;
    }
    .value-item h3 {
      font-size: 20px;
    }
    .value-title {
      padding-bottom: 5px;
    }
    /* Sahub Advantages Page CSS */
    .advantage-title {
      font-size: 22px;
    }
    /* Sahub All Apps Page CSS */
    .app-info h2 {
      font-size: 22px;
      line-height: 28px;
    }
    /* Sahub Alternatives Page CSS */
    .alternative-item h3 {
      font-size: 20px;
    }
    /* Sahub App Integration Page CSS */
    .app-integration-info h2 {
      font-size: 24px;
      line-height: 32px;
    }
    /* Sahub Brand Kit Page CSS */
    .brand-kit-item .sahub-image img {
      width: 150px;
    }
    .brand-color-item {
      width: 100%;
      margin: 0 0 15px;
    }
    .brand-font-item h2 {
      font-size: 40px;
    }
    .brand-font-item h4 {
      font-size: 20px;
    }
    .brand-kits-wrap .sahub-btn {
      padding: 15px 20px;
    }
    .brand-type-item .fa {
      font-size: 16px;
    }
    /* Sahub Careers Page CSS */
    .benefit-item {
      padding-left: 25px;
      padding-right: 25px;
    }
    .process-item {
      max-width: 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      padding-bottom: 15px;
    }
    /* Sahub Careers Single Page CSS */
    .career-detail-item h4 {
      font-size: 18px;
    }
    .career-detail-form input[type="submit"] {
      min-width: 100%;
    }
    /* Sahub Change Log Page CSS */
    .log-item h4 {
      font-size: 18px;
    }
    /* Sahub Contactus Page CSS */
    .contact-form input[type="submit"] {
      min-width: 100%;
    }
    .office-info {
      padding: 25px 20px;
    }
    .office-info .sahub-icon {
      margin-bottom: 15px;
    }
    .office-info h3 {
      font-size: 20px;
    }
    .office-place {
      padding-bottom: 5px;
    }
    .office-info ul {
      font-size: 15px;
    }
    .office-info ul li {
      padding-bottom: 3px;
    }
    .contact-wrap {
      text-align: center;
    }
    .contact-item .sahub-icon {
      margin: 0 auto 10px;
    }
    .contact-item {
      text-align: center;
    }
    /* Sahub Core Values Page CSS */
    .core-value-count {
      font-size: 40px;
      line-height: 44px;
    }
    .core-value-info,
    .one-fourth .core-value-info,
    .one-fourth-two .core-value-info,
    .one-half-two .core-value-info {
      padding: 25px;
    }
    .core-value-info h2 {
      font-size: 20px;
      line-height: 28px;
    }
    .core-value-info .sahub-icon {
      padding-bottom: 10px;
    }
    .core-value-info .sahub-icon img {
      width: 50px;
    }
    .customer-item {
      padding: 25px 20px;
    }
    .customer-item p {
      font-size: 16px;
      line-height: 24px;
    }
    .customer-info .sahub-image img {
      width: 50px;
    }
    .customer-inner-info {
      padding-top: 0;
    }
    .customer-inner-info h4 {
      font-size: 15px;
    }
    .customer-inner-info h5 {
      font-size: 13px;
    }
    /* Sahub Customers Page CSS */
    .sahub-trusted h2 {
      font-size: 22px;
    }
    .trusted-item {
      padding: 25px 20px;
    }
    /* Sahub Customers Single Page CSS */
    .customer-meta [class*="col-"] {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .customer-meta-item {
      padding-bottom: 20px;
    }
    .quote-info {
      padding-left: 20px;
      padding-right: 20px;
    }
    .quote-info p {
      font-size: 16px;
      line-height: 24px;
    }
    .customer-meta h3 {
      font-size: 18px;
    }
    /* Sahub Designing Page CSS */
    .promo-info h2 {
      font-size: 22px;
      line-height: 30px;
    }
    /* Sahub E Book Page CSS */
    .sahub-fullscreen-navigation {
      width: 100%;
    }
    .ebook-info .sahub-icon img {
      width: 140px;
    }
    /* Sahub Faq Page CSS */
    .questions-search h2 {
      font-size: 20px;
      line-height: 28px;
    }
    .questions-wrap h2 {
      font-size: 20px;
    }
    /* Sahub Get Started Guide Page CSS */
    .guide-info h3 {
      font-size: 18px;
    }
    /* Sahub It Page CSS */
    .sahub-background {
      min-height: 250px;
    }
    .it-info h2 {
      font-size: 22px;
      line-height: 30px;
    }
    .it-info .check-list {
      font-size: 15px;
    }
    /* Sahub No Openings Page CSS */
    .vacant-wrap .sahub-icon img {
      width: 220px;
    }
    .vacant-wrap h2 {
      font-size: 24px;
      line-height: 32px;
    }
    /* Sahub Partner Page CSS */
    .profit-form-wrap input[type="submit"] {
      min-width: 100%;
    }
    /* Sahub Pricings 01 Page CSS */
    .price-inner h2 {
      font-size: 32px;
      line-height: 40px;
    }
    .price-link {
      font-size: 13px;
    }
    .question-meta h3 {
      font-size: 18px;
    }
    .price-inner,
    .price-info {
      padding-left: 20px;
      padding-right: 20px;
    }
    .price-inner h3 {
      font-size: 22px;
    }
    /* Sahub Pricings 02 Page CSS */
    .price-item-wrap h2 {
      font-size: 32px;
      line-height: 40px;
    }
    /* Sahub Pricings 03 Page CSS */
    .pricings-style-three .price-users {
      margin-bottom: 15px;
    }
    .pricings-style-three .price-info p {
      padding-bottom: 10px;
    }
    .check-list {
      font-size: 15px;
    }
    .pricings-style-three .price-info p a {
      font-size: 14px;
    }
    .pricings-style-three .price {
      padding-bottom: 5px;
    }
    /* Sahub Pricings 04 Page CSS */
    .team-price h2 {
      font-size: 28px;
      line-height: 34px;
    }
    .plan-label span {
      display: block;
      margin: 5px 0;
    }
    /* Sahub Product Page CSS */
    .product-item h3 {
      font-size: 18px;
    }
    /* Sahub Product Single Page CSS */
    .doc-item .sahub-icon {
      float: none;
    }
    .doc-info {
      padding: 15px 0 0;
    }
    .doc-info h3 {
      font-size: 18px;
    }
    /* Sahub Referal Page CSS */
    .affiliate-info h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .affiliate-info h3 {
      font-size: 18px;
    }
    .affiliate-title {
      padding-bottom: 5px;
    }
    .affiliate-inner-info h4 {
      font-size: 16px;
    }
    .callout-style-three h2 {
      font-size: 22px;
      line-height: 32px;
    }
    /* Sahub Request A Demo Page CSS */
    .sahub-watch .section-title-wrap h2 {
      font-size: 22px;
      line-height: 30px;
    }
    .watch-form input[type="submit"] {
      min-width: 100%;
    }
    .watch-inner-wrap {
      padding: 20px 20px 2px;
    }
    .watch-wrap h3 {
      font-size: 22px;
    }
    .watch-image .video-btn {
      width: 53px;
      height: 53px;
      font-size: 16px;
    }
    /* Sahub Roadmap Page CSS */
    .roadmap-item h2 {
      font-size: 22px;
      line-height: 30px;
    }
    .number-list,
    .roadmap-item .check-list {
      font-size: 16px;
    }
    /* Sahub Security Page CSS */
    .security-item {
      text-align: center;
    }
    .security-item.sahub-hover .sahub-icon {
      padding-top: 0;
    }
    .security-item .sahub-icon {
      float: none;
    }
    .security-info {
      padding: 15px 0 0;
    }
    /* Sahub Services Page CSS */
    .tools-style-two .tool-item h3 {
      font-size: 18px;
    }
    /* Sahub Signin Signup Page CSS */
    .login-wrap {
      padding: 25px 20px 15px;
    }
    .login-meta-wrap .sahub-social {
      padding-top: 5px;
    }
    .login-meta-wrap .sahub-social.rounded a {
      width: 37px;
      height: 37px;
      line-height: 30px;
    }
    .login-meta {
      padding: 0 0 5px;
    }
    /* Sahub Sitemap Page CSS */
    .sitemap-item h3 {
      font-size: 20px;
    }
    /* Sahub Solutions Page CSS */
    .banner-style-three .banner-caption h1 {
      font-size: 22px;
      line-height: 32px;
    }
    .solution-item h2 {
      font-size: 22px;
    }
    .solutions-inner .sahub-icon {
      float: none;
    }
    .solutions-inner-info {
      padding: 15px 0 0;
    }
    .solutions-inner-info h3,
    .growth-chart h3 {
      font-size: 18px;
    }
    .customers-style-three .customer-info {
      padding-top: 0;
    }
    /* Sahub Success Stories Page CSS */
    .testimonials-style-six .testimonial-item p {
      font-size: 16px;
    }
    .story-info h4 {
      font-size: 18px;
    }
    /* Sahub Team Page CSS */
    .sahub-masonry[data-items="4"] .masonry-item {
      float: none;
      width: 100%;
    }
    .mate-item {
      max-width: 270px;
      margin: 0 auto;
    }
    .mate-info-wrap .modal-content {
      padding: 25px;
    }
    .mate-about p {
      font-size: 15px;
      line-height: 24px;
    }
    /* Sahub Workshops Page CSS */
    .workshop-filter {
      padding-top: 5px;
    }
    .workshop-filter .nav-tabs .nav-item {
      display: block;
      padding: 0 0 8px;
    }
    .workshop-filter .nav-tabs .nav-item:after {
      display: none;
    }
    .workshop-filter .nav-tabs .nav-link {
      display: inline-block;
    }
    .started-info input[type="email"] {
      width: 100%;
    }
    .started-info input[type="submit"] {
      min-width: 100%;
      margin-left: 0;
      position: relative;
    }
    .workshops-form input[type="submit"] {
      min-width: 100%;
    }
    /* Sahub Workshops Page CSS */
    .onsite-item,
    .onsite-item.onsite-spacer-one {
      padding-right: 0;
    }
    .onsite-item:nth-child(2n) {
      padding-left: 0;
    }
    .woocommerce form .form-row label.inline {
      margin: 10px 0 0 0px;
      width: 100%;
    }
    .sahub-btn-wrap .sahub-btn {
      padding: 10px 15px;
    }
    .page-title-wrap .breadcrumb li a,
    .page-title-wrap .breadcrumb li span {
      font-size: 16px;
    }
    .faq-count {
      width:8%;
      padding-top: 18px;
      padding-bottom: 5px;
      font-size: 16px;
    }
    .accordion-content .btn-link {
      font-size: 16px;
      padding-top: 15px;
    }
    .pricings-style-three .price-inner .sahub-icon {
      position: relative;
      margin-top: 15px;
    }
    .sahub-btn-wrap .sahub-btn {
      font-size: 16px;
    }
   
  }
  
  @media screen and (max-width:440px) { 
    span.blog-item-date,
    span.blog-item-month {
      font-size: 16px;
    }
    span.blog-item-month {
      margin-top: 4px;
    }
  
  }
  
  @media screen and (max-width:359px) {
    /* Sahub Normal CSS */
    .footer-wrap ul {
      font-size: 15px;
    }
    .banner-label {
      font-size: 14px;
    }
    .sahub-support h2 {
      font-size: 18px;
      line-height: 28px;
    }
    .page-title-wrap h1 {
      font-size: 26px;
      line-height: 32px;
    }
    .blog-item {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .news-bottom-metas span {
      padding-right: 0;
      margin-right: 0;
    }
    .news-info h3 {
      line-height: 1.2;
      font-size: 24px;
    }
    /* Sahub Home V2 Page CSS */
    .services-wrap .nav-justified .nav-item {
      width: 100%;
    }
    /* Sahub Aboutus Page CSS */
    .about-info h2 {
      font-size: 26px;
    }
    /* Sahub All Apps Page CSS */
    .app-info h2 {
      font-size: 20px;
    }
    /* Sahub Core Values Page CSS */
    .core-value-info h2 {
      font-size: 18px;
    }
    .customer-inner-info h4 {
      font-size: 14px;
    }
    /* Sahub Integration Single Page CSS */
    .promo-info h2 {
      font-size: 20px;
      line-height: 28px;
    }
    /* Sahub It Page CSS */
    .it-info h2 {
      font-size: 20px;
      line-height: 28px;
    }
    /* Sahub No Openings Page CSS */
    .vacant-wrap h2 {
      font-size: 22px;
      line-height: 30px;
    }
    /* Sahub Partner Page CSS */
    .features-style-four .feature-item,
    .investor-info {
      padding-left: 20px;
      padding-right: 20px;
    }
    .investor-info h4 {
      font-size: 18px;
    }
    /* Sahub Pricings 01 Page CSS */
    .pricings-style-three .price-inner,
    .pricings-style-three .price-info {
      padding-left: 20px;
      padding-right: 20px;
    }
    /* Sahub Referal Page CSS */
    .affiliate-info h2 {
      font-size: 22px;
      line-height: 28px;
    }
    .callout-style-three h2 {
      font-size: 20px;
      line-height: 30px;
    }
    /* Sahub Request A Demo Page CSS */
    .watch-wrap h3 {
      font-size: 20px;
    }
    /* Sahub Roadmap Page CSS */
    .roadmap-updates {
      font-size: 14px;
    }
    /* Sahub Security Page CSS */
    .security-info h3 {
      font-size: 18px;
    }
    /* Sahub Solutions Page CSS */
    .banner-style-three .banner-caption h1 {
      font-size: 20px;
      line-height: 30px;
    }
    .solution-item h2 {
      font-size: 20px;
    }
    div.wpcf7-validation-errors,
    div.wpcf7-acceptance-missing {
      margin-top: 0;
    }
  
  }
  @media(max-width:767px){
      .elementor-tabs .elementor-tabs-wrapper {
      display: block !important;
  }
      .elementor-element-450954c .elementor-tabs .elementor-tab-title {display: none;}
      .footer-wrap { padding: 40px 0;}
      #menu-new-menu #menu-item-4682{margin:0 20px 20px; width:auto;}
      .blogs-pg {padding: 50px 0 40px;}
      .blogs-pg .main-blog .bl .bl_body {position: relative;top: 0;left: 0;width: 100%;}
      .sahub-mid-wrap.sahub-post-single{padding-top:50px;}
      .sahub-related-posts {padding-top: 0;}
  }