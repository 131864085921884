@font-face {
  font-family: "remixicon";
  src: url("fonts/remixicon.eot?t=1590207869815"); /* IE9*/
  src: url("fonts/remixicon.eot?t=1590207869815#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/remixicon.woff2?t=1590207869815") format("woff2"),
    url("fonts/remixicon.woff?t=1590207869815") format("woff"),
    url("fonts/remixicon.ttf?t=1590207869815") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url("fonts/remixicon.svg?t=1590207869815#remixicon") format("svg"); /* iOS 4.1- */
  font-display: swap;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}
.card-radio {
  padding: 0;
}
.card-radio .form-check-label {
  background-color: #fff;
  border: 1px solid #e9ebec;
  border-radius: 0.25rem;
  display: block;
  overflow: hidden;
  padding: 1rem 32px 1rem 1rem;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-radio .form-check-label:hover {
  cursor: pointer;
}
.card-radio .form-check-input {
  display: none;
}
.card-radio .form-check-input:checked + .form-check-label {
  border-color: #405189 !important;
}
.card-radio .form-check-input:checked + .form-check-label:before {
  color: #405189;
  content: "\eb80";
  font-family: remixicon;
  font-size: 16px;
  position: absolute;
  right: 6px;
  top: 2px;
}
.bg-grey {
  background-color: #dee2e6;
}
.bg-primary-subtle {
  background-color: #fff;
}
.bg-black {
  background-color: #061f2b;
}
